import styles from './mysteryRecipeCard.module.scss';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Link, useParams } from 'react-router-dom';

import recipeEN from './../../pages/MysteryRecipePage/mysteryRecipe.json'
import recipeAr from './../../pages/MysteryRecipePage/mysteryRecipeAr.json'

 const MysteryRecipeCard = () => {
  const { locale } = useParams();

  const recipe = locale === 'en' ? recipeEN : recipeAr;

  const allowed = 
    localStorage.ingredient_1 && 
    localStorage.ingredient_2 && 
    localStorage.ingredient_3 && 
    localStorage.ingredient_4;

  return (
    <AnimationOnScroll
      animateIn="animate__fadeInUp"
      animateOnce={true}
      key='mysteryRecipe'>
      <Link className={styles.item} to={allowed ? `/${locale}/recipes/mystery-recipe` : `/${locale}/quiz` }>
        <div className={!allowed && styles.blurredImageWrapper}>
          <img src={recipe.image} 
            className={!allowed
            ? styles.recipeImageBlurred 
            : styles.recipeImage} alt="mystery recipe" />
        </div>
        {allowed ? <p>{recipe.title}</p> :<p>{recipe.titleLockedCTA}</p> }
      </Link>
    </AnimationOnScroll>
  )
}

export default MysteryRecipeCard;