import axios from 'axios';
import { BE_URL } from '../constants/Constants';

const translateAnswer = (value) => {
  return axios.get(`${BE_URL}/sanctum/csrf-cookie`).then((response) => {
    return axios
      .post(`${BE_URL}/api/translate`, { text: value })
      .then((res) => {
        if (res.data.status === 'success') {
          return res;
        } else {
          alert('Failed to translate! Smth is wrong on the server. Please try again later.')
        }
      })
      .catch((res) => {
        console.log(res);
      });
  });
};

export default translateAnswer;
