import styles from './registrationPage.module.scss';
import registrationSandwich from './../../assets/images/regPage/regSandwhich.png';
import pageSeparator from './../../assets/images/BG/white-sperator.png';

export const SideBanner = () => {
  return (
    <div className={styles.decorationImage}>
      <img
        src={pageSeparator}
        className={styles.pageSeparator}
        alt="paper divider"
      />
      <img
        src={registrationSandwich}
        className={styles.registrationSandwich}
        alt="registration Sandwich"
      />
    </div>
  );
};
