import { Link } from "react-router-dom";
import styles from "./LandBtn.module.scss";

const LangBtn = (props) => {
  return (
    <>
      <Link className={styles.langBTN} to = { props.content.langBTNLabel == "En" ? "/en" : "/ar" }>
        <span>{ props.content.langBTNLabel }</span>
      </Link>
    </>
  )
}

export default LangBtn;