import { useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import { useGlobalContext } from "../../utils/state/GlobalContext";
import styles from './productsPage.module.scss';

const ProductsPage = () => {
  const { isLoaded, commonData } = useGlobalContext();

  useEffect(() => {
    document.body.className = 'bg1';
    window.scrollTo({top: 0, behavior: 'smooth'});
    console.log('isLoaded', isLoaded);
    console.log('commonData', commonData);
  }, []);

  return (
    <Layout>  
    {isLoaded() && 
      <section className={styles.productsPage} id='productsPage'>
        <div className={styles.mainContent}>
          <h1 className={styles.title}>{commonData.productsPage.title}</h1>
          <div className={styles.productsWrap}>
          {commonData.productsPage.items.map((item) => {
              return (
                <a key={`product-${item.title}`} className={`item ${styles.card}` } href={item.action} target="_blank" rel="noopener noreferrer">
                <div className={ styles.wrap }  style={ { background:item.bg } }>
                  <img alt={`product-${item.title}`} src={item.image} />
                </div>
                <h3>{item.title}</h3>
              </a>
              );
            })}
          </div>
        </div>
      </section>
    } 
    </Layout>
  )
}

export default ProductsPage;