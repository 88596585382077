import { Navigate, useLocation, useParams } from 'react-router-dom';

const ProtectedRegistration = ({ children }) => {
  const { locale } = useParams();
  let location = useLocation();

  const allowed = 
  localStorage.ingredient_1 || 
  localStorage.ingredient_2 || 
  localStorage.ingredient_3 || 
  localStorage.ingredient_4;

  if (!allowed) {
    return <Navigate to={`/${locale}/404`} state={{ from: location }} replace />;
  } 

  return (
    <>
      {children}
    </>
  )
}

export default ProtectedRegistration;