const PlayButton = () => {
  return (
    <div>
      <svg viewBox="0 0 24 24">
        <path d="M10 16.5v-9l6 4.5M12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2z" fill="currentColor"></path>
      </svg>
    </div>
  )
}

export default PlayButton;