import styles from './learnPage.module.scss';
import { useEffect, useState } from 'react';
import ArKeyboard from '../../components/Keyboards/ArKeyboard';
import EngKeyboard from '../../components/Keyboards/EngKeyboard';
import Layout from '../../components/Layout/Layout';
import { useGlobalContext } from '../../utils/state/GlobalContext';
import sandwhich from './../../assets/images/learnPage/learningSandwhich.png';
// import sunBG from './../../assets/images/BG/sunBG.png';
import { Link, useParams } from 'react-router-dom';

const LearnPage = () => {
  const { locale } = useParams();

  const [language, setLanguage] = useState(locale);
  const { commonData, isLoaded } = useGlobalContext();

  useEffect(() => {
    document.body.className = 'bg1';
    setTimeout(() => {
      window.scrollTo({top: 0, behavior: 'smooth'});
      document.getElementById('header').style.position = 'absolute';
    }, 200);
  }, []);

  return (
    <Layout>
      {isLoaded() ? (
        <div className={styles.learningPage}>
          <div className={styles.learnHeading}>
            <div className={styles.imageWrap}>
              {/* <img src={sunBG} alt="sunlight" className={styles.sandwhichBG} /> */}
              <img src={sandwhich} alt="learning sandwhich" />
              <h1 className={styles.title}>{commonData.learning.title}</h1>
            </div>
            <div className={styles.headingContent}>
              <p>{commonData.learning.desc} <Link className={styles.link} to={`/${locale}/quiz`}>{commonData.learning.linkTitle}</Link></p>
              <div className={styles.tabsWrap}>
                <button
                  onClick={() => setLanguage('en')}
                  className={language === 'en' ? styles.tabActive : styles.tab}
                >
                  English
                </button>
                <button
                  onClick={() => setLanguage('ar')}
                  className={language === 'ar' ? styles.tabActive : styles.tab}
                >
                  Arabic
                </button>
              </div>
            </div>
          </div>
          <div className={styles.keyboard}>
            {language === 'en' ? <EngKeyboard /> : <ArKeyboard />}
          </div>
        </div>
      ) : (
        <></>
      )}
    </Layout>
  );
};

export default LearnPage;
