import styles from './decodeSection.module.scss';
import { RedBtn } from '../../common';

import happySandwich from '../../../assets/images/Home/happySandwich.png';
import { useParams } from 'react-router-dom';

const DecodeSection = (props) => {
  const { locale } = useParams();

  const allUnlocked = 
    localStorage.ingredient_1 &&
    localStorage.ingredient_2 &&
    localStorage.ingredient_3 &&
    localStorage.ingredient_4;

  return (
    <section className={styles.decodeSection}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <h1>{props.content.title}</h1>
          <p>{props.content.desc2} </p>
          <div className={styles.imageWrapMobile}>
            <img src={happySandwich} alt="banner" className={styles.bannerImg} />
          </div>
          <RedBtn bigger style={styles.btn} label={props.content.cta} href={allUnlocked ? `/${locale}/recipes/mystery-recipe` : `/${locale}/quiz`} />
        </div>
        <div className={styles.imageWrap}>
          <img src={happySandwich} alt="banner" className={styles.bannerImg} />
        </div>
      </div>
    </section>
  );
};

export default DecodeSection;
