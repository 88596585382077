import styles from './soundButton.module.scss';
import { ReactComponent as PlayArrow } from './../../../assets/images/MysteryPage/playArrowCut.svg';
import { ReactComponent as CorrectMark } from './../../../assets/images/MysteryPage/correct.svg';
// import { useState } from 'react';
// import SoundAnimation from '../SoundAnimation/SoundAnimation';

const SoundButton = ({ correct, product, sound, id }) => {
  // const [playing, setPlaying] = useState(false);

  const start = (soundtoplay) => {
    new Audio(soundtoplay).play();
    sessionStorage.setItem('lastPlayed', product)

    // setPlaying(true);
    // setTimeout(() => {
    //   setPlaying(false);
    // }, 1500);
    
    if (id === '1') {
      document.getElementById('1').classList.add('playButtonActive'); 
      document.getElementById('2').classList.remove('playButtonActive'); 
      document.getElementById('3').classList.remove('playButtonActive'); 
      document.getElementById('4').classList.remove('playButtonActive'); 
    } else if (id === '2') {
      document.getElementById('1').classList.remove('playButtonActive'); 
      document.getElementById('2').classList.add('playButtonActive'); 
      document.getElementById('3').classList.remove('playButtonActive'); 
      document.getElementById('4').classList.remove('playButtonActive'); 
    } else if (id === '3') {
      document.getElementById('1').classList.remove('playButtonActive'); 
      document.getElementById('2').classList.remove('playButtonActive'); 
      document.getElementById('3').classList.add('playButtonActive'); 
      document.getElementById('4').classList.remove('playButtonActive'); 
    } else if (id === '4') {
      document.getElementById('1').classList.remove('playButtonActive'); 
      document.getElementById('2').classList.remove('playButtonActive'); 
      document.getElementById('3').classList.remove('playButtonActive'); 
      document.getElementById('4').classList.add('playButtonActive'); 
    }

  } 

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <div className={styles.playButtonWrap} >
      {/* <a href={`#${id}`}>SET TARGET</a> */}
      <button
        id={id}
        disabled={correct}
        className={correct ? styles.playButtonCorrect : styles.playButton}
        onClick={() => start(sound)}>
        <div className={styles.buttonIcon}>
          {/* {playing ? <SoundAnimation/> : <>{correct ? <CorrectMark /> : <PlayArrow /> }</>} */}
          {correct ? <CorrectMark /> : <PlayArrow className={styles.playArrow} /> }
        </div>
      </button>
      <div className={styles.productTitle}>{correct ? capitalizeFirstLetter(product) : '???'}</div>
    </div>
  );
};

export default SoundButton;
