import styles from './keyboard.module.scss';
import A from './../../assets/audios/soundsEn/A.mp3';
import B from './../../assets/audios/soundsEn/B.mp3';
import C from './../../assets/audios/soundsEn/C.mp3';
import D from './../../assets/audios/soundsEn/D.mp3';
import E from './../../assets/audios/soundsEn/E.mp3';
import F from './../../assets/audios/soundsEn/F.mp3';
import G from './../../assets/audios/soundsEn/G.mp3';
import H from './../../assets/audios/soundsEn/H.mp3';
import I from './../../assets/audios/soundsEn/I.mp3';
import J from './../../assets/audios/soundsEn/J.mp3'; 
import K from './../../assets/audios/soundsEn/K.mp3';
import L from './../../assets/audios/soundsEn/L.mp3';
import M from './../../assets/audios/soundsEn/M.mp3';
import N from './../../assets/audios/soundsEn/N.mp3';
import O from './../../assets/audios/soundsEn/O.mp3';
import P from './../../assets/audios/soundsEn/P.mp3';
import Q from './../../assets/audios/soundsEn/Q.mp3';
import R from './../../assets/audios/soundsEn/R.mp3';
import S from './../../assets/audios/soundsEn/S.mp3';
import T from './../../assets/audios/soundsEn/T.mp3';
import U from './../../assets/audios/soundsEn/U.mp3';
import V from './../../assets/audios/soundsEn/V.mp3';
import W from './../../assets/audios/soundsEn/W.mp3';
import X from './../../assets/audios/soundsEn/X.mp3';
import Y from './../../assets/audios/soundsEn/Y.mp3';
import Z from './../../assets/audios/soundsEn/Z.mp3';

const EngKeyboard = () => {
  const start = (sound) => {
    new Audio(sound).play();
  };

  const soundToPlay = [
    {
      sound: A,
      title: 'A',
    },
    {
      sound: B,
      title: 'B',
    },
    {
      sound: C,
      title: 'C',
    },
    {
      sound: D,
      title: 'D',
    },
    {
      sound: E,
      title: 'E',
    },
    {
      sound: F,
      title: 'F',
    },
    {
      sound: G,
      title: 'G',
    },
    {
      sound: H,
      title: 'H',
    },
    {
      sound: I,
      title: 'I',
    },
    {
      sound: J,
      title: 'J',
    },
    {
      sound: K,
      title: 'K',
    },
    {
      sound: L,
      title: 'L',
    },
    {
      sound: M,
      title: 'M',
    },
    {
      sound: N,
      title: 'N',
    },
    {
      sound: O,
      title: 'O',
    },
    {
      sound: P,
      title: 'P',
    },
    {
      sound: Q,
      title: 'Q',
    },
    {
      sound: R,
      title: 'R',
    },
    {
      sound: S,
      title: 'S',
    },
    {
      sound: T,
      title: 'T',
    },
    {
      sound: U,
      title: 'U',
    },
    {
      sound: V,
      title: 'V',
    },
    {
      sound: W,
      title: 'W',
    },
    {
      sound: X,
      title: 'X',
    },
    {
      sound: Y,
      title: 'Y',
    },
    {
      sound: Z,
      title: 'Z',
    },
  ];

  return (
    <div className={styles.keyboardWrap}>
      <div className={styles.keyboard}>
        {soundToPlay.map((sound, i) => (
          <button
            className={styles.soundButton}
            key={`sound-to-learn-${i}`}
            onClick={() => start(sound.sound)}
          >
            {sound.title}
          </button>
        ))}
      </div>
    </div>
  );
};

export default EngKeyboard;
