import { Link } from 'react-router-dom';
import styles from './header.module.scss';
import logo from '../../../assets/images/garameeshLogo.png';
import LangBtn from '../langBtn/LangBtn';
import SidebarMobile from '../Sidebar/SidebarMobile';
import Sidebar from '../Sidebar/Sidebar';

const Header = (props) => {
  return (
    <div className={styles.headerBar} id="header">
      {window.innerWidth < 600 ? (
        <>
          <SidebarMobile />
          <Link to={`/${props.locale}/home`}>
            <img src={logo} alt="logo" className={styles.logo} />
          </Link>
        </>
      ) : (
        <div className={styles.headerLeft}>
          <Sidebar />
          <Link to={`/${props.locale}/home`}>
            <img src={logo} alt="logo" className={styles.logo} />
          </Link>
        </div>
      )}

      <LangBtn content={props.content} />
    </div>
  );
};

export default Header;
