export default function shuffle(initArray) {

  let array = [...initArray];
  
  if (!Array.isArray(array)) {
    return []
  }

  let currentIndex = array.length,  randomIndex;

  while (currentIndex != 0) {

    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}