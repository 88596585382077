import {  useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGlobalContext } from '../../utils/state/GlobalContext';
import Layout from "../../components/Layout/Layout";

import styles from './Static.module.scss';

const Static = (props) => {
  const thisPageKey = props.content;
  //
  const { getStatic } = useGlobalContext();
  const { pageData, isLoaded } = useGlobalContext();
  const { locale } = useParams();
      
  useEffect(() => {
    document.body.className = "bg1";
    const res = getStatic(locale, thisPageKey);
    res.then ((e) => {
      window.scrollTo(0, 0);
    });
  },[props.content]); 

  return (
    <Layout>
      { isLoaded () ?
        <section className= { styles.static } dangerouslySetInnerHTML={ pageData.static[thisPageKey] } ></section>
      :
        <></>
      }
    </Layout>
  )
}

export default Static;


