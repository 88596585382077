const Ingredients = (props) => {
  console.log('......', props.content);
  return (
    <>
      {props.content.items.length > 0 ? (
        <>
          <h4>{props.content.title}</h4>
          <ul>
            {props.content.items.map((ing) => {
              return <li key={`ing-${ing}`}>{ing}</li>;
            })}
          </ul>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Ingredients;
