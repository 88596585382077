import styles from './quizPage.module.scss';
import { useState, useEffect } from 'react';
import Layout from '../../components/Layout/Layout';
import SoundButton from '../../partials/mysteryPage/SoundButton/SoundButton';
import CongratsPopupCompleted from '../../components/CongratsPopup/CongratsPopupCompleted';
import CongratsPopupInProgress from '../../components/CongratsPopup/CongratsPopupInProgress';
import { useGlobalContext } from '../../utils/state/GlobalContext';
import { Link, useParams } from 'react-router-dom';
import translateAnswer from '../../utils/func/translateAnswer';
import { RedBtn } from '../../partials/common';
import { BE_URL } from './../../utils/constants/Constants';
import axios from 'axios';
import hidden from './hiddenIngredients.json'

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import { CircularProgress, FormControl, MenuItem, OutlinedInput, Select } from '@mui/material';
import Box from '@mui/material/Box';

import mysteryRecipe from './../../assets/images/MysteryPage/EN_Locked.png';
import mysteryRecipeAR from './../../assets/images/MysteryPage/MysteryLockedAR.png';
import mysteryUnlocked from './../../assets/images/MysteryPage/EN_Unlocked.png';
import mysteryUnlockedAR from './../../assets/images/MysteryPage/MysteryUnlockedAR.png';
import { ReactComponent as Repeat } from './../../assets/images/MysteryPage/repeat.svg';
import { ReactComponent as DropdownArrow } from './../../assets/images/svg/dropdownArrow.svg';

// ! Secret ingredients sounds
import rusk from './../../assets/audios/mysteryPage/rusk.mp3';
import ruskAR from './../../assets/audios/mysteryPage/ruskAR.mp3';

import banana from './../../assets/audios/mysteryPage/banana.mp3';
import bananaAR from './../../assets/audios/mysteryPage/bananaAR.mp3';

import cream from './../../assets/audios/mysteryPage/cream.mp3';
import creamAR from './../../assets/audios/mysteryPage/creamAR.mp3';

import pistachio from './../../assets/audios/mysteryPage/pistachio.mp3';
import pistachioAR from './../../assets/audios/mysteryPage/pistachioAR.mp3';

import honey  from './../../assets/audios/mysteryPage/honey.mp3';
import honeyAR  from './../../assets/audios/mysteryPage/honeyAR.mp3';

import butter from './../../assets/audios/mysteryPage/butter.mp3';
import butterAR from './../../assets/audios/mysteryPage/butterAR.mp3';
import PlayButton from '../../partials/mysteryPage/PlayButtton/PlayButton';
import shuffle from './shuffleArray';

const QuizPage = () => {
  const { isLoaded, commonData } = useGlobalContext();
  const { locale } = useParams();

  const options = locale === 'en' ?
   [
    {id: 1, title: 'Oats'},
    {id: 2, title: 'Strawberry'},
    {id: 3, title: 'Raspberry'},
    {id: 4, title: 'Tomato'},
    {id: 5, title: 'Peanut Butter'},
    {id: 6, title: 'Chocolate'},
    {id: 7, title: 'Chicken'},
    {id: 8, title: 'Cheese'},
    {id: 9, title: 'Mango'},
    {id: 10, title: 'Salmon'},
    {id: 11, title: 'Apple '},
    {id: 12, title: 'Quinoa'},
    {id: 13, title: 'Jam'},
    {id: 14, title: 'Marinara'},
    {id: 15, title: 'Thyme'},
    {id: 16, title: 'Rusk'},
    {id: 17, title: 'Banana'},
    {id: 18, title: 'Cream'},
    {id: 19, title: 'Pistachio'},
    {id: 20, title: 'Honey'},
    {id: 21, title: 'Butter'},
  ] :
  [
    {id: 1, title: 'الشوفان'},
    {id: 2, title: 'الفراولة'},
    {id: 3, title: 'توت العُليق'},
    {id: 4, title: 'طماطم'},
    {id: 5, title: 'زبدة الفول السوداني'},
    {id: 6, title: 'شوكولاتة'},
    {id: 7, title: 'دجاج'},
    {id: 8, title: 'جبنه'},
    {id: 9, title: 'مانجو'},
    {id: 10, title: 'سلمون'},
    {id: 11, title: 'تفاح '},
    {id: 12, title: 'الكينوا'},
    {id: 13, title: 'مربى'},
    {id: 14, title: 'مارينارا'},
    {id: 15, title: 'زعتر'},
    {id: 16, title: 'شابورة'},
    {id: 17, title: 'موز'},
    {id: 18, title: 'قشطة'},
    {id: 19, title: 'فستق'},
    {id: 20, title: 'عسل'},
    {id: 21, title: 'زبدة'},
  ];

  const allUnlocked = 
    localStorage.ingredient_1 &&
    localStorage.ingredient_2 &&
    localStorage.ingredient_3 &&
    localStorage.ingredient_4;

  const hiddens = locale === 'en' ? hidden.eng : hidden.ar;

  const [randomSet, setRandomSet] = useState(false);

  const [shuffledArr, setDropdownOptions] = useState(options.slice(0, 10)) 
  
  function shuffleOptions() {
    setInpValue('');
    
    setTimeout(() => {
      // let t = [...options];

      let t2 = shuffle([...options]).slice(0, 10);

      if (!t2.find((item) => item.title === sessionStorage.lastPlayed.charAt(0).toUpperCase() + sessionStorage.lastPlayed.slice(1))) {
        // t2.push({id: 16, title: sessionStorage.lastPlayed});
        t2.shift();
        t2.push({id: 22, title: sessionStorage.lastPlayed.charAt(0).toUpperCase() + sessionStorage.lastPlayed.slice(1) });

        setDropdownOptions(shuffle(t2));
      } else {
        setDropdownOptions(shuffle(t2));
      }

      // setInpValue(t2[0].title);
      
    }, 500);
  }

  // ! GA function 
  const sendGA = (label) => {
    window.dataLayer.push({
        event: "gaEvent",
        event_name: "quiz_submit",
        // submit_click: {
        event_category: "translator",
        event_action: "submit",
        event_label: label
      // }
    })
  }
  

  const [open, setOpen] = useState(false) 
  const [inProgressOpen, setInProgressOpen] = useState(false)
  const [inpValue, setInpValue] = useState('');
  const [translatedSound, setTranslatedSound] = useState('');
  const [soundName, setSoundName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [soundEnded, setSoundEnded] = useState(false);

  const handleChange = (event) => {
    document.getElementsByClassName('MuiInputBase-formControl')[0].style.backgroundColor = 'white';
    setInpValue(event.target.value);
  };

  // ! ingredients - items names in LS should match with cases in SWITCH case 
  const [firstCorrect, setFirstCorrect] = useState(localStorage.getItem(hidden.eng.hiddenIngredient1) || localStorage.getItem(hidden.ar.hiddenIngredient1));
  const [secondCorrect, setSecondCorrect] = useState(localStorage.getItem(hidden.eng.hiddenIngredient2) || localStorage.getItem(hidden.ar.hiddenIngredient2));
  const [thirdCorrect, setThirdCorrect] = useState(localStorage.getItem(hidden.eng.hiddenIngredient3) ||  localStorage.getItem(hidden.ar.hiddenIngredient3));
  const [fourthCorrect, setFourthCorrect] = useState(localStorage.getItem(hidden.eng.hiddenIngredient4) ||  localStorage.getItem(hidden.ar.hiddenIngredient4));
  const [fifthCorrect, setFifthCorrect] = useState(localStorage.getItem(hidden.eng.hiddenIngredient5) ||  localStorage.getItem(hidden.ar.hiddenIngredient5));
  const [sixCorrect, setSixCorrect] = useState(localStorage.getItem(hidden.eng.hiddenIngredient6) ||  localStorage.getItem(hidden.ar.hiddenIngredient6));

  // const handleInputChange = (e) => {
  //   setInpValue(e.target.value.replace(/[^\u0621-\u064A a-zA-Z]/gi, ''))
  // }

  const handleTranslate = () => {
    setIsLoading(true);
    const response = translateAnswer(inpValue.trim());
    response.then((res) => {
      setIsLoading(false)
      setSoundName(inpValue.trim())
      setTranslatedSound(res.data.data.url)
    })
  }
  

  const handleSubmit = () => {
    console.log('submitting started...')
    
    if (sessionStorage.lastPlayed.toLowerCase() === inpValue.trim().toLowerCase()) {
      localStorage.setItem(inpValue.trim().toLowerCase(), 'unlocked')
      setInpValue('');
      sendGA('correct');

      if (randomSet === 1) {
        switch (inpValue.trim().toLowerCase()) {
          case hiddens.hiddenIngredient1: setFirstCorrect(true); localStorage.setItem('ingredient_1', inpValue.toLowerCase()) ;
            break;
          case hiddens.hiddenIngredient3: setThirdCorrect(true); localStorage.setItem('ingredient_2', inpValue.toLowerCase()) ;
            break;
          case hiddens.hiddenIngredient5: setFifthCorrect(true); localStorage.setItem('ingredient_3', inpValue.toLowerCase()) ;
            break;
          case hiddens.hiddenIngredient6: setSixCorrect(true); localStorage.setItem('ingredient_4', inpValue.toLowerCase());
            break;
            default:
            break;
        }
      }

      if (randomSet === 2) {
        switch (inpValue.trim().toLowerCase()) {
          case hiddens.hiddenIngredient6: setSixCorrect(true); localStorage.setItem('ingredient_1', inpValue.toLowerCase()) ;
            break;
          case hiddens.hiddenIngredient5: setFifthCorrect(true); localStorage.setItem('ingredient_2', inpValue.toLowerCase()) ;
            break;
          case hiddens.hiddenIngredient4: setFourthCorrect(true); localStorage.setItem('ingredient_3', inpValue.toLowerCase()) ;
            break;
          case hiddens.hiddenIngredient3: setThirdCorrect(true); localStorage.setItem('ingredient_4', inpValue.toLowerCase()) ;
            break;
          default:
            break;
        }
      }

      if (randomSet === 3) {
        switch (inpValue.trim().toLowerCase()) {
          case hiddens.hiddenIngredient4: setFourthCorrect(true); localStorage.setItem('ingredient_1', inpValue.toLowerCase());
            break;
          case hiddens.hiddenIngredient3: setThirdCorrect(true); localStorage.setItem('ingredient_2', inpValue.toLowerCase());
            break;
          case hiddens.hiddenIngredient2: setSecondCorrect(true); localStorage.setItem('ingredient_3', inpValue.toLowerCase());
            break;
          case hiddens.hiddenIngredient1: setFirstCorrect(true); localStorage.setItem('ingredient_4', inpValue.toLowerCase());
            break;
          default:
            break;
        }
      }

      if (randomSet === 4) {
        switch (inpValue.trim().toLowerCase()) {
          case hiddens.hiddenIngredient2: setSecondCorrect(true); localStorage.setItem('ingredient_1', inpValue.toLowerCase()) ;
            break;
          case hiddens.hiddenIngredient4: setFourthCorrect(true); localStorage.setItem('ingredient_2', inpValue.toLowerCase()) ;
            break;
          case hiddens.hiddenIngredient6: setSixCorrect(true); localStorage.setItem('ingredient_3', inpValue.toLowerCase()) ;
            break;
          case hiddens.hiddenIngredient5: setFifthCorrect(true); localStorage.setItem('ingredient_4', inpValue.toLowerCase()) ;
            break;
          default:
            break;
        }
      }

      if (randomSet === 5) {
        switch (inpValue.trim().toLowerCase()) {
          case hiddens.hiddenIngredient6: setSixCorrect(true); localStorage.setItem('ingredient_1', inpValue.toLowerCase()) ;
            break;
          case hiddens.hiddenIngredient1: setFirstCorrect(true); localStorage.setItem('ingredient_2', inpValue.toLowerCase()) ;
            break;
          case hiddens.hiddenIngredient4: setFourthCorrect(true); localStorage.setItem('ingredient_3', inpValue.toLowerCase()) ;
            break;
          case hiddens.hiddenIngredient2: setSecondCorrect(true); localStorage.setItem('ingredient_4', inpValue.toLowerCase()) ;
            break;
          default:
            break;
        }
      }

      if (randomSet === 6) {
        switch (inpValue.trim().toLowerCase()) {
          case hiddens.hiddenIngredient3: setThirdCorrect(true); localStorage.setItem('ingredient_1', inpValue.toLowerCase()) ;
            break;
          case hiddens.hiddenIngredient6: setSixCorrect(true); localStorage.setItem('ingredient_2', inpValue.toLowerCase()) ;
            break;
          case hiddens.hiddenIngredient5: setFifthCorrect(true); localStorage.setItem('ingredient_3', inpValue.toLowerCase()) ;
            break;
          case hiddens.hiddenIngredient2: setSecondCorrect(true); localStorage.setItem('ingredient_4', inpValue.toLowerCase()) ;
            break;
          default:
            break;
        }
      }

      if (randomSet === 7) {
        switch (inpValue.trim().toLowerCase()) {
          case hiddens.hiddenIngredient5: setFifthCorrect(true); localStorage.setItem('ingredient_1', inpValue.toLowerCase()) ;
            break;
          case hiddens.hiddenIngredient1: setFirstCorrect(true); localStorage.setItem('ingredient_2', inpValue.toLowerCase()) ;
            break;
          case hiddens.hiddenIngredient2: setSecondCorrect(true); localStorage.setItem('ingredient_3', inpValue.toLowerCase()) ;
            break;
          case hiddens.hiddenIngredient4: setFourthCorrect(true); localStorage.setItem('ingredient_4', inpValue.toLowerCase()) ;
            break;
          default:
            break;
        }
      }

      if (randomSet === 8) {
        switch (inpValue.trim().toLowerCase()) {
          case hiddens.hiddenIngredient1: setFirstCorrect(true); localStorage.setItem('ingredient_1', inpValue.toLowerCase()) ;
            break;
          case hiddens.hiddenIngredient6: setSixCorrect(true); localStorage.setItem('ingredient_2', inpValue.toLowerCase()) ;
            break;
          case hiddens.hiddenIngredient2: setSecondCorrect(true); localStorage.setItem('ingredient_3', inpValue.toLowerCase()) ;
            break;
          case hiddens.hiddenIngredient5: setFifthCorrect(true); localStorage.setItem('ingredient_4', inpValue.toLowerCase()) ;
            break;
          default:
            break;
        }
      }

      if (randomSet === 9) {
        switch (inpValue.trim().toLowerCase()) {
          case hiddens.hiddenIngredient5: setFifthCorrect(true); localStorage.setItem('ingredient_1', inpValue.toLowerCase()) ;
            break;
          case hiddens.hiddenIngredient4: setFourthCorrect(true); localStorage.setItem('ingredient_2', inpValue.toLowerCase()) ;
            break;
          case hiddens.hiddenIngredient3: setThirdCorrect(true); localStorage.setItem('ingredient_3', inpValue.toLowerCase()) ;
            break;
          case hiddens.hiddenIngredient1: setFirstCorrect(true); localStorage.setItem('ingredient_4', inpValue.toLowerCase()) ;
            break;
          default:
            break;
        }
      }

      if (randomSet === 10) {
        switch (inpValue.trim().toLowerCase()) {
          case hiddens.hiddenIngredient2: setSecondCorrect(true); localStorage.setItem('ingredient_1', inpValue.toLowerCase()) ;
            break;
          case hiddens.hiddenIngredient4: setFourthCorrect(true); localStorage.setItem('ingredient_2', inpValue.toLowerCase()) ;
            break;
          case hiddens.hiddenIngredient6: setSixCorrect(true); localStorage.setItem('ingredient_3', inpValue.toLowerCase()) ;
            break;
          case hiddens.hiddenIngredient3: setThirdCorrect(true); localStorage.setItem('ingredient_4', inpValue.toLowerCase()) ;
            break;
          default:
            break;
        }
      }
      
      if (localStorage.getItem('firstName')) {

        let values = {
          firstName: localStorage.firstName,
          lastName: localStorage.lastName,
          email: localStorage.email,
          mobile: localStorage.mobile,
          random_order: localStorage.randomSet,
          ingredient_1: localStorage.getItem('ingredient_1'),
          ingredient_2: localStorage.getItem('ingredient_2'),
          ingredient_3: localStorage.getItem('ingredient_3'),
          ingredient_4: localStorage.getItem('ingredient_4'),
        }

        return axios.get(`${BE_URL}/sanctum/csrf-cookie`).then((response) => {
          return axios
            .post(`${BE_URL}/api/register`, values)
            .then((res) => {
              if (res.data.status === 'success') {
                // console.log('Request is sent successfully:', res.data);
                if (
                  localStorage.ingredient_1 &&
                  localStorage.ingredient_2 &&
                  localStorage.ingredient_3 &&
                  localStorage.ingredient_4) {
                  setOpen(true) 
                }
              } else {
                console.log(res);
                alert('Smth went wrong on the server.')
              }
            })
            .catch((res) => {
              console.log(res.response.data);
              alert('Smth went wrong on the server.')
              window.location.reload()
            });
        });
      } else if (
        localStorage.ingredient_1 &&
        localStorage.ingredient_2 &&
        localStorage.ingredient_3 &&
        localStorage.ingredient_4) {
        setOpen(true)
      } else {
        setInProgressOpen(true)
      }

      // IF ANSWER IN INPUT IS WRONG
    } else {
      sendGA('wrong');
      document.getElementsByClassName('MuiInputBase-formControl')[0].style.backgroundColor = '#FFD6D6';
    }
  }

  const handleIconOpen = () => {
    document.getElementById('dropdownIcon').style.transform = "rotate(-180deg)";
  }

  const handleIconClose = () => {
    document.getElementById('dropdownIcon').style.transform = 'unset';
  }

  useEffect(() => {
    document.body.className = 'bg1';
    window.scrollTo({top: 0, behavior: 'smooth'});

    // setInpValue(shuffledArr[0].title)
    
    if (localStorage.getItem('randomSet')) {
      setRandomSet(Number(localStorage.randomSet))
    } else {
      const randomNum = Math.floor(Math.random() * 10) + 1;
      setRandomSet(randomNum)
      localStorage.setItem('randomSet', randomNum);
    }

    setTimeout(() => {
      document.getElementById('1').classList.add('playButtonActive');
    }, 700);

    setTimeout(() => {
      // document.getElementById('1').classList.add('playButtonActive');
      
      document.getElementById('1').addEventListener('click', shuffleOptions)
      document.getElementById('2').addEventListener('click', shuffleOptions)
      document.getElementById('3').addEventListener('click', shuffleOptions)
      document.getElementById('4').addEventListener('click', shuffleOptions)
    }, 1500);

  }, []);

  return (
    <Layout>  
    {isLoaded() && 
      <>
        <div className={styles.mysteryPage} id='mysteryPage'>
          <div className={styles.mainContent}>

            <h1 className={styles.title}>{commonData.mysteryPage.title}</h1>
            <div className={styles.imageWrapUpper}>
            {locale === 'en' ? 
              <img src={allUnlocked ? mysteryUnlocked : mysteryRecipe} alt="mystery recipe"/> :
              <img src={allUnlocked ? mysteryUnlockedAR : mysteryRecipeAR} alt="mystery recipe"/> 
            }
            </div>
            <p className={styles.description}>{commonData.mysteryPage.desc} <Link className={styles.descriptionLink} to={`/${locale}/learning`}>{commonData.mysteryPage.descLink}</Link></p>
            {(() => {
              switch (randomSet) {
                case 1:  return (
                <div className={styles.buttonsWrap}>
                  <SoundButton id="1" product={hiddens.hiddenIngredient1} sound={locale === 'en' ? rusk : ruskAR} correct={firstCorrect}/>
                  <SoundButton id="2" product={hiddens.hiddenIngredient3} sound={locale === 'en' ? cream : creamAR} correct={thirdCorrect}/>
                  <SoundButton id="3" product={hiddens.hiddenIngredient5} sound={locale === 'en' ? honey : honeyAR} correct={fifthCorrect}/>
                  <SoundButton id="4" product={hiddens.hiddenIngredient6} sound={locale === 'en' ? butter : butterAR} correct={sixCorrect}/>
                </div> )

                case 2:  return (
                <div className={styles.buttonsWrap}>
                  <SoundButton id="1" product={hiddens.hiddenIngredient6} sound={locale === 'en' ? butter : butterAR} correct={sixCorrect}/>
                  <SoundButton id="2" product={hiddens.hiddenIngredient5} sound={locale === 'en' ? honey : honeyAR} correct={fifthCorrect}/>
                  <SoundButton id="3" product={hiddens.hiddenIngredient4} sound={locale === 'en' ? pistachio : pistachioAR} correct={fourthCorrect}/>
                  <SoundButton id="4" product={hiddens.hiddenIngredient3} sound={locale === 'en' ? cream : creamAR} correct={thirdCorrect}/>
                </div> )

                case 3:  return (
                <div className={styles.buttonsWrap}>
                  <SoundButton id="1" product={hiddens.hiddenIngredient4} sound={locale === 'en' ? pistachio : pistachioAR} correct={fourthCorrect}/>
                  <SoundButton id="2" product={hiddens.hiddenIngredient3} sound={locale === 'en' ? cream : creamAR} correct={thirdCorrect}/>
                  <SoundButton id="3" product={hiddens.hiddenIngredient2} sound={locale === 'en' ? banana : bananaAR} correct={secondCorrect}/>
                  <SoundButton id="4" product={hiddens.hiddenIngredient1} sound={locale === 'en' ? rusk : ruskAR} correct={firstCorrect}/>
                </div> )

                case 4:  return (
                <div className={styles.buttonsWrap}>
                  <SoundButton id="1" product={hiddens.hiddenIngredient2} sound={locale === 'en' ? banana : bananaAR} correct={secondCorrect}/>
                  <SoundButton id="2" product={hiddens.hiddenIngredient4} sound={locale === 'en' ? pistachio : pistachioAR} correct={fourthCorrect}/>
                  <SoundButton id="3" product={hiddens.hiddenIngredient6} sound={locale === 'en' ? butter : butterAR} correct={sixCorrect}/>
                  <SoundButton id="4" product={hiddens.hiddenIngredient5} sound={locale === 'en' ? honey : honeyAR} correct={fifthCorrect}/>
                </div> )

                case 5:  return (
                <div className={styles.buttonsWrap}>
                  <SoundButton id="1" product={hiddens.hiddenIngredient6} sound={locale === 'en' ? butter : butterAR} correct={sixCorrect}/>
                  <SoundButton id="2" product={hiddens.hiddenIngredient1} sound={locale === 'en' ? rusk : ruskAR} correct={firstCorrect}/>
                  <SoundButton id="3" product={hiddens.hiddenIngredient4} sound={locale === 'en' ? pistachio : pistachioAR} correct={fourthCorrect}/>
                  <SoundButton id="4" product={hiddens.hiddenIngredient2} sound={locale === 'en' ? banana : bananaAR} correct={secondCorrect}/>
                </div> )

                case 6:  return (
                <div className={styles.buttonsWrap}>
                  <SoundButton id="1" product={hiddens.hiddenIngredient3} sound={locale === 'en' ? cream : creamAR} correct={thirdCorrect}/>
                  <SoundButton id="2" product={hiddens.hiddenIngredient6} sound={locale === 'en' ? butter : butterAR} correct={sixCorrect}/>
                  <SoundButton id="3" product={hiddens.hiddenIngredient5} sound={locale === 'en' ? honey : honeyAR} correct={fifthCorrect}/>
                  <SoundButton id="4" product={hiddens.hiddenIngredient2} sound={locale === 'en' ? banana : bananaAR} correct={secondCorrect}/>
                </div> )

                case 7:  return (
                <div className={styles.buttonsWrap}>
                  <SoundButton id="1" product={hiddens.hiddenIngredient5} sound={locale === 'en' ? honey : honeyAR} correct={fifthCorrect}/>
                  <SoundButton id="2" product={hiddens.hiddenIngredient1} sound={locale === 'en' ? rusk : ruskAR} correct={firstCorrect}/>
                  <SoundButton id="3" product={hiddens.hiddenIngredient2} sound={locale === 'en' ? banana : bananaAR} correct={secondCorrect}/>
                  <SoundButton id="4" product={hiddens.hiddenIngredient4} sound={locale === 'en' ? pistachio : pistachioAR} correct={fourthCorrect}/>
                </div> )

                case 8:  return (
                <div className={styles.buttonsWrap}>
                  <SoundButton id="1" product={hiddens.hiddenIngredient1} sound={locale === 'en' ? rusk : ruskAR} correct={firstCorrect}/>
                  <SoundButton id="2" product={hiddens.hiddenIngredient6} sound={locale === 'en' ? butter : butterAR} correct={sixCorrect}/>
                  <SoundButton id="3" product={hiddens.hiddenIngredient5} sound={locale === 'en' ? honey : honeyAR} correct={fifthCorrect}/>
                  <SoundButton id="4" product={hiddens.hiddenIngredient2} sound={locale === 'en' ? banana : bananaAR} correct={secondCorrect}/>
                </div> )

                case 9:  return (
                <div className={styles.buttonsWrap}>
                  <SoundButton id="1" product={hiddens.hiddenIngredient5} sound={locale === 'en' ? honey : honeyAR} correct={fifthCorrect}/>
                  <SoundButton id="2" product={hiddens.hiddenIngredient4} sound={locale === 'en' ? pistachio : pistachioAR} correct={fourthCorrect}/>
                  <SoundButton id="3" product={hiddens.hiddenIngredient3} sound={locale === 'en' ? cream : creamAR} correct={thirdCorrect}/>
                  <SoundButton id="4" product={hiddens.hiddenIngredient1} sound={locale === 'en' ? rusk : ruskAR} correct={firstCorrect}/>
                </div> )

                case 10:  return (
                <div className={styles.buttonsWrap}>
                  <SoundButton id="1" product={hiddens.hiddenIngredient2} sound={locale === 'en' ? banana : bananaAR} correct={secondCorrect}/>
                  <SoundButton id="2" product={hiddens.hiddenIngredient4} sound={locale === 'en' ? pistachio : pistachioAR} correct={fourthCorrect}/>
                  <SoundButton id="3" product={hiddens.hiddenIngredient6} sound={locale === 'en' ? butter : butterAR} correct={sixCorrect}/>
                  <SoundButton id="4" product={hiddens.hiddenIngredient3} sound={locale === 'en' ? cream : creamAR} correct={thirdCorrect}/>
                </div> )
            
                default:
                  break;
              }
            })()}

            <div className={styles.footeringButtons}>
              <div className={styles.inputWrapper}>
                <FormControl id="quizDropdown" fullWidth>
                  <Select
                    id="quizDropdownSelect"
                    IconComponent={() => (
                      <DropdownArrow className="dropdownIcon" id='dropdownIcon' />
                    )}
                    onOpen={handleIconOpen}
                    onClose={handleIconClose}
                    value={inpValue}
                    onChange={handleChange}
                    displayEmpty
                    input={<OutlinedInput />}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <span className='selectPlaceholder'>{commonData.mysteryPage.placeholder}</span>;
                      }
                      return inpValue;
                    }}
                  >
                    {/* <MenuItem disabled value=""><span className='selectPlaceholder'>{commonData.mysteryPage.placeholder}</span></MenuItem> */}
                    {shuffledArr.map((item, i) => (
                      <MenuItem key={i} value={item.title}>{item.title}</MenuItem>
                    ))}
                  </Select>
                </FormControl> 
              <button className={styles.translateButton} onClick={handleTranslate} disabled={isLoading}>
                {isLoading ? <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress color='warning' size='25px' /></Box> : <span>{commonData.mysteryPage.translate} </span>}
              </button>
              </div>

              {translatedSound ? 
                <AudioPlayer 
                  layout="horizontal" 
                  src={translatedSound} 
                  showJumpControls={false} 
                  defaultCurrentTime="00:00" 
                  defaultDuration="00:00" 
                  onPlay={() => setSoundEnded(false)}
                  onEnded={() => setSoundEnded(true)}
                  customIcons={{
                    play: {...soundEnded ? <Repeat/> : <PlayButton/>} 
                  }}
                  header={soundName ? soundName : commonData.mysteryPage.translator }/> :
                <AudioPlayer 
                  style={{ filter: 'brightness(0.7)', pointerEvents: 'none' }} 
                  layout="horizontal" 
                  src={translatedSound} 
                  defaultCurrentTime="00:00" 
                  defaultDuration="00:00" 
                  showJumpControls={false}  
                  header={soundName ? soundName : commonData.mysteryPage.translator}/>}
              
              {allUnlocked ?  
              <RedBtn autoFocus bigger
                style={styles.redButtonViewFull}
                href={`/${locale}/recipes/mystery-recipe`}
                label={commonData.mysteryPage.viewFull} />
              : <button type="submit" className={styles.submitButton} onClick={handleSubmit}>{commonData.mysteryPage.submit}</button>}
            </div>
          </div>

          <div className={styles.imageWrapSide}>
          {locale === 'en' ? 
            <img src={allUnlocked ? mysteryUnlocked : mysteryRecipe} alt="mystery recipe" /> :
            <img src={allUnlocked ? mysteryUnlockedAR : mysteryRecipeAR} alt="mystery recipe" />
          }
          </div>

          </div>
          <CongratsPopupInProgress isOpen={inProgressOpen} setOpen={setInProgressOpen.bind(this)}/>
          <CongratsPopupCompleted isOpen={open} setOpen={setOpen.bind(this)}/>
      </>
    }
    </Layout>
  );
};

export default QuizPage;
