import { useState } from "react";
import styles from "./translateSection.module.scss";
// import { RedBtn } from '../../common';
import { useParams } from "react-router-dom";

import happySandwich from "../../../assets/images/Home/decoder.png";
import TranslateForm from "../TranslateForm/TranslateForm";

const TranslateSection = (props) => {
  const { locale } = useParams();

  return (
    <section className={styles.translateSection}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <h1>{props.content.title}</h1>

          <TranslateForm locale={locale} content={props.content} />
          <div className={styles.imageWrapMobile}></div>
        </div>
        <div className={styles.imageWrap}>
          <img src={happySandwich} alt="banner" className={styles.bannerImg} />
        </div>
      </div>
    </section>
  );
};

export default TranslateSection;
