import styles from './Directions.module.scss';
import DirectionsCarousel from "./DirectionsCarousel/DirectionsCarousel"

const Directions = (props) => {
  return (
    <>
      <div className={props.mysteryRecipe ? styles.StepsMystery : styles.Steps } >
        <div className={ styles.wrapper } >
          <h1>{ props.content.title } </h1>
          <DirectionsCarousel 
            key={ props.content.title } 
            locale={ props.locale } 
            items={ props.content.items } />
        </div>
      </div>
    </>
  )
}

export default Directions;