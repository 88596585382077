import { useRef } from "react";
import videojs from 'video.js';
import VideoJS from "../../home/Randomizer/VideoJS"; 
import videoEN from "./../../../assets/videos/MysteryRecipePage/EN.mp4";
import videoAR from "./../../../assets/videos/MysteryRecipePage/AR.mp4";
import { useParams } from "react-router-dom";
// import videoAR "../../home/Randomizer/VideoJS";

const MysteryVideo = () => {
  const playerRef = useRef(null);
  const { locale } = useParams();

  const handlePlayerReady = (player) => {
  
    playerRef.current = player;
    let animateRecipe = true;
    // You can handle player events here, for example:
    player.on('timeupdate', (e) => {
      const ct = player.currentTime();
      //
      if ((ct > 2) & !animateRecipe) {
        animateRecipe = true;
        // findRandom();
      } else if (ct < 2) {
        animateRecipe = false;
      }
    });
    player.on('waiting', () => {
      videojs.log('player is waiting');
    });
  
    player.on('dispose', () => {
      videojs.log('player will dispose');
    });
  };
  
  const videoJsOptions = {
    options: {
      autoplay: false,
      controls: true,
      muted: false,
      preload: 'auto',
      playsinline: true,
      fluid: true,
      sources: [
        {
          src: locale === 'en' ? videoEN : videoAR,
          type: 'video/mp4',
        },
      ],
    },
  };

  return (
    <VideoJS {...videoJsOptions} onReady={handlePlayerReady} />
  );
};

export default MysteryVideo;