import { useRef, useState } from 'react';
import styles from './randomizerPhase2.module.scss';
import { RedBtn } from '../../common/index';
import VideoJS from './VideoJS.js';
import videojs from 'video.js';
import { Link } from 'react-router-dom';

const RandomizerPhase2 = (props) => {
  const playerRef = useRef(null);
  const [randomRecipe, setRandomRecipe] = useState(null);
  const [crateBeenOpened, setCrateBeenOpened] = useState(false);
  const videoJsOptions = {
    options: {
      poster: props.content.poster,
      autoplay: false,
      controls: false,
      muted: true,
      preload: 'auto',
      playsinline: true,
      fluid: true,
      sources: [
        {
          src: props.content.video,
          type: 'video/mp4',
        },
      ],
    },
  };

  const findRandom = () => {
    const rnd = Math.floor(Math.random() * props.content.items.length);
    setRandomRecipe(props.content.items[rnd]);

    setCrateBeenOpened(true)

  };

  const showRandomRecipe = () => {
    setRandomRecipe(null);
    playerRef.current.play();
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;
    let animateRecipe = true;
    // You can handle player events here, for example:
    player.on('timeupdate', (e) => {
      const ct = player.currentTime();

      if ((ct > 2) & !animateRecipe) {
        animateRecipe = true;
        findRandom();
      } else if (ct < 2) {
        animateRecipe = false;
      }
    });
    player.on('waiting', () => {
      videojs.log('player is waiting');
    });

    player.on('dispose', () => {
      videojs.log('player will dispose');
    });
  };

  return (
    <>
      <div className={styles.randomizer}>
        <div className={styles.wrapper}>
        {window.innerWidth > 768 &&
        <div className={styles.container}>
            {randomRecipe && (
              <Link className={styles.card} to={`/${props.locale}/recipes/${randomRecipe.slug}`}>
                <div className="animate__animated animate__zoomIn">
                  <div className={styles.wrap}>
                    <img src={randomRecipe.image} alt='mystery box' />
                    <span>{randomRecipe.title}</span>
                  </div>
                </div>
              </Link>
            )}
            <div className={styles.player}>
              <VideoJS {...videoJsOptions} onReady={handlePlayerReady} />
            </div>
          </div>}
          <div className={styles.content}>
            <h1>{props.content.title} </h1>
            <p>{props.content.desc} </p>

            {window.innerWidth <= 768 &&
        <div className={styles.container}>
            {randomRecipe && (
              <Link className={styles.card} to={`/${props.locale}/recipes/${randomRecipe.slug}`} >
                <div className="animate__animated animate__zoomIn">
                  <div className={styles.wrap}>
                    <img alt='mystery recipe' src={randomRecipe.image} />
                    <span>{randomRecipe.title}</span>
                  </div>
                </div>
              </Link>
            )}
            <div className={styles.player}>
              <VideoJS {...videoJsOptions} onReady={handlePlayerReady} />
            </div>
          </div>}

          <div className={styles.btnWrap}>
            <RedBtn
              style={styles.btn}
              onClick={showRandomRecipe}
              label={crateBeenOpened ? props.content.ctaAnother : props.content.cta}
              href={props.content.ctaAction}
            />

            {crateBeenOpened && 
              <RedBtn
                style={styles.btn}
                color="yellow"
                label={props.content.viewRecipes}
                href={`/${props.locale}/recipes`}
              />
            }
          </div>
        </div>
        </div>

      </div>
    </>
  );
};
export default RandomizerPhase2;
