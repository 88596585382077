import React, { useEffect } from 'react';
import {InlineShareButtons} from 'sharethis-reactjs';


const InlineButtons = ({url}) => {
  // useEffect(() => {
  //   window.__sharethis__.load('inline-share-buttons', {
  //     alignment: 'center',
  //     id: 'custom-inline-buttons',
  //     enabled: true,
  //     labels : "share",
  //     font_size: 11,
  //     padding: 8,
  //     radius: 0,
  //     networks: [           // which networks to include (see FOLLOW NETWORKS)
  //         'twitter',
  //         'facebook',
  //         'whatsapp',
  //         'messenger'
  //       ],
  //     size: 32,
  //     show_mobile_buttons: true,
  //     spacing: 0,
  //     url: url,
  //     title: "Garameesh Arabia",
  //     image: "https://18955-presscdn-pagely.netdna-ssl.com/wp-content/uploads/2016/12/ShareThisLogo2x.png",
  //     description: "My Custom Description",
  //     username: "garameesharabia"
  //   });
  // }, []);

  return (<>
  
    <InlineShareButtons
          config={{
            alignment: 'center',  // alignment of buttons (left, center, right)
            id: 'custom-inline-buttons',
            color: 'social',      // set the color of buttons (social, white)
            enabled: true,        // show/hide buttons (true, false)
            font_size: 11,        // font size for the buttons
            labels: 'null',        // button labels (cta, counts, null)
            networks: [           // which networks to include (see SHARING NETWORKS)
              'twitter',
              'facebook',
              'whatsapp',
              'messenger',
              'email'
            ],
            padding: 8,          // padding within buttons (INTEGER)
            radius: 0,            // the corner radius on each button (INTEGER)
            show_total: false,
            size: 32,             // the size of each button (INTEGER)


            // OPTIONAL PARAMETERS

            min_count: 10,                    // (threshold for total share count to be displayed)
            url: url,                         // (defaults to current url)
            image: 'https://18955-presscdn-pagely.netdna-ssl.com/wp-content/uploads/2016/12/ShareThisLogo2x.png',  // (defaults to og:image or twitter:image)
            description: 'Please check the encoded video',       // (defaults to og:description or twitter:description)
            title: 'Garameesh Arabia',            // (defaults to og:title or twitter:title)
            username: 'garameesharabia' // (only for twitter sharing)
          }}
        />
    </>);
}

export default InlineButtons;
