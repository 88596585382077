import {  useEffect } from 'react';
import styles from './prizesPage.module.scss';
import Layout from '../../components/Layout/Layout';
import { useGlobalContext } from '../../utils/state/GlobalContext';
import { useParams } from 'react-router-dom';
import { RedBtn } from '../../partials/common';

import prize1 from './../../assets/images/PrizesPage/Prize1.png'
import prize2 from './../../assets/images/PrizesPage/Prize2.png'
import prize3 from './../../assets/images/PrizesPage/Prize3.png'
import paper1 from './../../assets/images/PrizesPage/1.png'
import paper2 from './../../assets/images/PrizesPage/2.png'
import paper3 from './../../assets/images/PrizesPage/3.png'

const PrizesPage = () => {
  const { isLoaded, commonData } = useGlobalContext();
  const { locale } = useParams();

  useEffect(() => {
    document.body.className = 'bg2';
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);

  return (
    <Layout>  
    {isLoaded() && 
      <section className={styles.mysteryPage} id='mysteryPage'>
        <div className={styles.mainContent}>
          <h1 className={styles.title}>{commonData.prizesPage.title}</h1>
          <div className={styles.prizesWrap}>

          {/* A section where the text and paper are splitted*/}
            <div className={styles.prize}>
              <img className={styles.prizeImage} src={prize2} alt=''/>
              <img className={styles.prizePaper} src={paper2} alt=''/>
              <p className={`${styles.prizeTitle} ${styles.prizeTitle2}`}>{commonData.prizesPage.prize2}</p>
            </div>
            <div className={`${styles.prize} ${styles.prize1}`}>
              <img className={styles.prizeImage} src={prize1} alt=''/>
              <img className={styles.prizePaper} src={paper1} alt=''/>
              <div className={`${styles.prizeTitle} ${styles.prizeTitle1}`}>{commonData.prizesPage.prize1}</div>
            </div>
            <div className={styles.prize}>
              <img className={styles.prizeImage} src={prize3} alt=''/>
              <img className={styles.prizePaper} src={paper3} alt=''/>
              <p className={`${styles.prizeTitle} ${styles.prizeTitle3}`}>{commonData.prizesPage.prize3}</p>
            </div>
          </div>
            
          <RedBtn 
            style={styles.button}
            href={`/${locale}/quiz`}
            label={commonData.prizesPage.cta} />
        </div>
      </section>
    } 
    </Layout>
  );
};

export default PrizesPage;
