import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Directions, Ingredients } from '../../partials/recipes';
import { PaperSeperator } from '../../partials/home';
import Layout from '../../components/Layout/Layout';
import styles from './mysteryRecipe.module.scss';
import { InlineShareButtons } from 'sharethis-reactjs';

import recipeEN from './mysteryRecipe.json'
import recipeAr from './mysteryRecipeAr.json'
import PopularMysteryPage from '../../partials/recipes/Popular/PopularMysteryPage';
import MysteryVideo from '../../partials/mysteryPage/MysteryVideo/MysteryVideo';
import ProtectedMysteryRecipe from '../../utils/func/ProtectedMysteryRecipe';

const MysteryRecipePage = () => {
  const { locale } = useParams();

  const recipe = locale === 'en' ? recipeEN : recipeAr;

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    document.body.className = 'bg2';
  }, []);

  return (
    <ProtectedMysteryRecipe>
      <Layout>
        <div className={styles.detailsPage}>
          <div className={styles.banner}>
            <img className={styles.bannerImg} src={recipe.image} alt={recipe.title}/>

            <div className={styles.txtwrap}>
              <h1>{recipe.title}</h1>
              <p>{recipe.desc}</p>
              <div className={styles.actionBar}>
                <p className={styles.shareCTA}>{locale === 'en' ? 'Share It Now' : 'شاركوها الآن'}</p>
                <InlineShareButtons
                  config={{
                    alignment: locale === 'en' ? 'left' : 'right',
                    color: 'social',
                    enabled: true,
                    font_size: 16,
                    labels: 'null',
                    language: 'en',
                    networks: [
                      'whatsapp',
                      'messenger',
                      'facebook',
                      'twitter',
                    ],
                    padding: 12,
                    radius: 4,
                    show_total: false,
                    size: 40,
                    url: `https://www.garameesharabia.com/${locale}/${recipe.title}`,
                    description: `${recipe.title}`, // (defaults to og:description or twitter:description)
                    title: `${recipe.desc}`, // (defaults to og:title or twitter:title)
                  }}
                />
              </div>
            </div>
          </div>

          <PaperSeperator/>

          <section className={styles.orangeSection}>
          <div className={styles.ingWrap}>
            <div className={styles.mysteryVideo}><MysteryVideo/></div>
            {recipe.ing.map((ingGroup) => {
              return (
                <Ingredients key={ingGroup.title + Math.random()} content={ingGroup} />
              );
            })}
            </div>
          </section>  

          <div className={styles.instWrap}>
            {recipe.inst.map((instrGroup) => {
              if (instrGroup.items.length > 0) {
                return (
                  <Directions
                    key={instrGroup.title}
                    mysteryRecipe={true}
                    map={instrGroup}
                    locale={locale}
                    content={instrGroup} />
                );
              }
            })}
          </div>
        </div>
        <PaperSeperator />
        <PopularMysteryPage locale={locale} content={recipe.related} />
      </Layout>
    </ProtectedMysteryRecipe>
  );
};

export default MysteryRecipePage;
