import styles from './registrationPage.module.scss';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { BE_URL } from './../../utils/constants/Constants';
import { useFormik } from 'formik';
import Layout from '../../components/Layout/Layout';
import { Link, useParams } from 'react-router-dom';
import { ReactComponent as WarningIcon } from './../../assets/images/regPage/warningIcon.svg';
import { SideBanner } from './SideBanner';
import { validateEmail } from '../../utils/func/validateEmail';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import RegSuccessPopup from '../../components/RegSuccessPopup/RegSuccessPopup';
import { useGlobalContext } from '../../utils/state/GlobalContext';
import ProtectedRegistration from '../../utils/func/ProtectedRegistration';

const RegistrationPage = () => {
  const [error, setError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [success, setSuccess] = useState(false);

  const { locale } = useParams();
  const { isLoaded, commonData } = useGlobalContext();

  const handleMobile = (value, data, event, formattedValue) => {
    formik.handleChange(event);
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
      random_order: '1',
    },

    onSubmit: (values) => {
      setError(false);
      setEmailError(false);
      setFirstNameError(false);
      setLastNameError(false);

      if (values.firstName === '') {
        setFirstNameError(true);
      }

      if (values.lastName === '') {
        setLastNameError(true);
      }

      if (values.email === '') {
        setEmailError(true);
      }

      // console.log(values);

      if (
        !values.firstName ||
        !values.lastName ||
        !values.email ||
        !values.mobile ||
        !validateEmail(values.email, setEmailError)
      ) {
        setError(true);
      } else {

        // adding new variables to the form values
        values.random_order = localStorage.getItem('randomSet');
        values.ingredient_1 = localStorage.getItem('ingredient_1');
        values.ingredient_2 = localStorage.getItem('ingredient_2');
        values.ingredient_3 = localStorage.getItem('ingredient_3');
        values.ingredient_4 = localStorage.getItem('ingredient_4');

        return axios.get(`${BE_URL}/sanctum/csrf-cookie`).then((response) => {
          return axios
            .post(`${BE_URL}/api/register`, values)
            .then((res) => {
              if (res.data.status === 'success') {
                setSuccess(true);
                localStorage.setItem('firstName', res.data.data.user.firstName);
                localStorage.setItem('lastName', res.data.data.user.lastName);
                localStorage.setItem('email', res.data.data.user.email);
                localStorage.setItem('mobile', res.data.data.user.mobile);
              }
            })
            .catch((res) => {
              console.log(res.response.data);
              alert('Smth went wrong on the server.')
              // window.location.reload()
            });
        });
      }
    },
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    document.body.className = 'bg1';

    setTimeout(() => {
      document.getElementById('header').style.position = 'absolute';
    }, 300);
  }, []);

  return (
    <ProtectedRegistration>
    <Layout>
    {isLoaded() &&
      <>
      <div className={styles.registrationPage}>
        <div className={styles.leftSide}>
          <div className={styles.content}>
            <h1 className={styles.title}>{commonData.registrationPage.title}</h1>
            <p className={styles.description}>{commonData.registrationPage.desc}</p>

            <p className={error ? styles.formError : styles.invisible}> <WarningIcon /> {commonData.registrationPage.error} </p>

            <form onSubmit={formik.handleSubmit} id="registration-form">
              <div className={styles.formWrapper}>
                <div className={styles.namesWrap}>
                  {/* First Name */}
                  <div className={styles.customInputWrap}>
                    <label htmlFor="firstName" className={styles.customLabel}>{commonData.registrationPage.firstName}</label>
                    <input
                      className={firstNameError ? styles.customInputError : styles.customInput}
                      name="firstName"
                      id="firstName"
                      value={formik.values.firstName}
                      onChange={formik.handleChange}/>
                  </div>

                  {/* Last Name */}
                  <div className={styles.customInputWrap}>
                    <label htmlFor="lastName" className={styles.customLabel}>{commonData.registrationPage.lastName}</label>
                    <input
                      className={lastNameError ? styles.customInputError : styles.customInput}
                      name="lastName"
                      id="lastName"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}/>
                  </div>
                </div>

                {/* Email */}
                <div className={styles.customInputWrap}>
                  <label htmlFor="email" className={styles.customLabel}>{commonData.registrationPage.email}</label>
                  <input
                    className={emailError ? styles.customInputError : styles.customInput}
                    name="email"
                    id="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}/>
                </div>

                {/* Mobile */}
                <div className={styles.customInputWrap}>
                  <label htmlFor="mobile" className={styles.customLabel}>{commonData.registrationPage.mobile}</label>
                  <PhoneInput
                    inputProps={{name: 'mobile', id: 'mobile'}}
                    value={formik.values.mobile}
                    onChange={handleMobile}
                    country="sa"
                    preferredCountries={['sa', 'ae']}
                    enableSearch
                    countryCodeEditable={false} />
                </div>
              </div>

              <p className={styles.userAgreement}>
                {commonData.registrationPage.policiesNotice1}
                <Link to={`/${locale}/t&c`}>
                  {commonData.registrationPage.userAgreement}
                </Link>
                {commonData.registrationPage.policiesNotice2}
                <Link to={`/${locale}/pp`}>
                  {commonData.registrationPage.privacyNotice}
                </Link>{' '}
                {commonData.registrationPage.policiesNotice3}
              </p>
              <button type="submit" className={styles.redBtn}>{commonData.registrationPage.btn}</button>
            </form>
          </div>
        </div>
        
        <SideBanner />
      </div>
      {success && <RegSuccessPopup isOpen={true} />}
      </>
    }
    </Layout>
    </ProtectedRegistration>
  );
};

export default RegistrationPage;
