import axios from 'axios';
import { useState, createContext, useContext } from 'react';
import { API } from '../constants/Constants';

const GlobalContext = createContext({});

export const useGlobalContext = () => useContext(GlobalContext);

export const GlobalContextProvider = ({ children }) => {
  const [commonDataLoaded, setCommonDataLoaded] = useState(false);
  const [commonData, setCommonData] = useState({});

  const [pageData, setPageData] = useState({
    home: undefined,
    recipes: undefined,
    recipeDetails: {}, //store individual recipie details
    notfound: true,
    static: {
      tc: undefined,
      pp: undefined,
    },
  });

  const isLoaded = (key, slug) => {
    if (!key && !slug) {
      //if both blank check status of common data only
      return commonDataLoaded;
    } else if (key) {
      //check if page sub key is loaded (applicable for home )
      return commonDataLoaded & (pageData[key] !== undefined);
    } else if (key & slug) {
      //check if conetnt is loaded for the slug applicable for static and recipie details
      return commonDataLoaded & (pageData[key][slug] !== undefined);
    }
  };

  const getCommon = (lang) => {
    return axios
      .get(`${API}/${lang}/common.json?nocache=${Math.random()}`)
      .then((res) => {
        setCommonDataLoaded(true);
        setCommonData(res.data);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  };

  //below Fucntion used for json based content
  const getPageContent = (lang, key, slug) => {
    const url = slug
      ? `${API}/${lang}/${key}/${slug}.json?nocache=${Math.random()}`
      : `${API}/${lang}/${key}.json?nocache=${Math.random()}`;
    //
    return axios
      .get(url)
      .then((res) => {
        let newPageData;
        if (slug) {
          newPageData = {
            ...pageData[key],
            [slug]: res.data,
          };
          setPageData({
            ...pageData,
            [key]: newPageData,
          });
        } else {
          newPageData = {
            ...pageData,
            [key]: res.data,
          };
          setPageData(newPageData);
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  };

  //below  used for jsob based content
  const getStatic = (lang, content) => {
    return axios
      .get(`${API}/${lang}/static/${content}.html?nocache=${Math.random()}`)
      .then((res) => {
        //update staticData
        const newStatic = {
          ...pageData.static,
          [content]: {
            __html: res.data,
          },
        };
        //update PageData object
        setPageData({
          ...pageData,
          static: newStatic,
        });
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  };

  return (
    <GlobalContext.Provider
      value={{
        commonData,
        pageData,
        getCommon,
        getPageContent,
        getStatic,
        isLoaded,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
