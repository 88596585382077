import styles from './ProductCarousel.module.scss';
import OwlCarousel_RTL from 'react-owl-carousel-rtl';
import OwlCarousel from 'react-owl-carousel';


const ProductCarousel = (props) => {
  const DOM = (props.locale === "en") ? OwlCarousel : OwlCarousel_RTL;

  return (
    <>
      <DOM rtl={props.locale === "en" ?"false": "true"} dots={false} autoWidth='false' className='owl-theme' margin={30}>
        { props.items.map(i => {
            return (
              <a key={`product-${i.title}`} className={ `item ${styles.card}` } href={i.action} target="_blank" rel="noopener noreferrer">
                <div className={ styles.wrap }  style={ { background:i.bg } }>
                  <img alt={`product-${i.title}`} src={i.image} />
                </div>
                <h3>{i.title}</h3>
              </a>
            )
          })
        }
      </DOM>
    </>
  )
}

export default ProductCarousel;