import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { Fragment } from 'react';
import { useState } from 'react';

import { NavLink, useParams } from 'react-router-dom';
import { useGlobalContext } from '../../../utils/state/GlobalContext';
import pageSeparator from './../../../assets/images/BG/sidebar.png';
import { ReactComponent as Burger } from './../../../assets/images/svg/burgerMenu.svg'

export default function SidebarMobile() {
  const { locale } = useParams();
  const { commonData } = useGlobalContext();

  const allUnlocked = 
    localStorage.ingredient_1 &&
    localStorage.ingredient_2 &&
    localStorage.ingredient_3 &&
    localStorage.ingredient_4;

  const [state, setState] = useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem>
          <ListItemButton>
            <NavLink to={`/${locale}/home`} className="sidebar-nav-item">
              {commonData.sidebar.home}
            </NavLink>
          </ListItemButton>
        </ListItem>

        <ListItem>
          <ListItemButton>
            <NavLink to={`/${locale}/products`} className="sidebar-nav-item">
              {commonData.sidebar.products}
            </NavLink>
          </ListItemButton>
        </ListItem>

        <ListItem>
          <ListItemButton>
            <NavLink to={`/${locale}/recipes`} className="sidebar-nav-item">
              {commonData.sidebar.recipes}
            </NavLink>
          </ListItemButton>
        </ListItem>

        <ListItem>
          <ListItemButton>
            <NavLink to={`/${locale}/learning`} className="sidebar-nav-item">
              {commonData.sidebar.learn}
            </NavLink>
          </ListItemButton>
        </ListItem>

        <ListItem>
          <ListItemButton>
            <NavLink to={`/${locale}/prizes`} className="sidebar-nav-item">
              {commonData.sidebar.prizes}
            </NavLink>
          </ListItemButton>
        </ListItem>

        <ListItem>
          <ListItemButton>
            <NavLink to={`/${locale}/${allUnlocked ? 'recipes/mystery-recipe' : 'quiz'}`} className="sidebar-nav-item">
              {commonData.sidebar.mystery}
            </NavLink>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      {['top'].map((anchor) => (
        <Fragment key={anchor}>
          <button className="burgerButton" onClick={toggleDrawer(anchor, true)}>
            <Burger/>
          </button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
            <span className="ripped-paper"></span>
            <img
              src={pageSeparator}
              className="pageSeparatorSidebarTop"
              alt="paper divider"
            />
          </Drawer>
        </Fragment>
      ))}
    </div>
  );
}
