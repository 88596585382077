import styles from './Popular.module.scss';
import PopularCarousel from './PopularCarousel/PopularCarousel';

const PopularMysteryPage = (props) => {
  return (
    <section className={styles.PopularYellow}>
      <div className={styles.wrapper}>
        <h1>{props.content.title} </h1>
        {/*<p>{ props.content.desc}</p> */}
        <PopularCarousel locale={props.locale} items={props.content.items} />
      </div>
    </section>
  );
};

export default PopularMysteryPage;
