import { CircleSpinnerOverlay } from 'react-spinner-overlay';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Header, Footer } from '../../partials/common';
import { useGlobalContext } from '../../utils/state/GlobalContext';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from './Layout.module.scss';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-246727025-1');

const Layout = ({ children }) => {
  const { getCommon, isLoaded, setLocale, commonData } = useGlobalContext();
  const { locale } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const res = getCommon(locale);
    ReactGA.pageview(window.location.pathname + window.location.search);
    res.then((response) => {
      if (response.code == 'ERR_BAD_REQUEST') {
        navigate('/en/404');
      }
    });
  }, []);

  return (
    <>
      {!isLoaded() ? (
        <>
          <CircleSpinnerOverlay
            loading={!isLoaded()}
            overlayColor="rgba(255,1216,78,1)"
            color="rgba(238,45,38,0.5)"
          />
        </>
      ) : (
        <>
          <Helmet>
            <html lang={locale} />
          </Helmet>

          <div id="outer-container" style={{ height: '100%' }}>
            <Header locale={locale} content={commonData} />

            <main id="page-wrap" className={styles.pageWrap}>
              {children}
            </main>
            <Footer locale={locale} content={commonData.footer} />
          </div>
        </>
      )}
    </>
  );
};

export default Layout;
