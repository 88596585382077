import styles from './keyboard.module.scss';

import sound1 from './../../assets/audios/soundsAr/ا.mp3';
import sound2 from './../../assets/audios/soundsAr/ب.mp3';
import sound3 from './../../assets/audios/soundsAr/ت.mp3';
import sound4 from './../../assets/audios/soundsAr/ث.mp3';
//
import sound5 from './../../assets/audios/soundsAr/ج.mp3';
import sound6 from './../../assets/audios/soundsAr/ح.mp3';
import sound7 from './../../assets/audios/soundsAr/خ.mp3';
import sound8 from './../../assets/audios/soundsAr/د.mp3';
//
import sound9 from './../../assets/audios/soundsAr/ذ.mp3';
import sound10 from './../../assets/audios/soundsAr/ر.mp3';
import sound11 from './../../assets/audios/soundsAr/ز.mp3';
import sound12 from './../../assets/audios/soundsAr/س.mp3';
//
import sound13 from './../../assets/audios/soundsAr/ش.mp3';
import sound14 from './../../assets/audios/soundsAr/ص.mp3';
import sound15 from './../../assets/audios/soundsAr/ض.mp3';
import sound16 from './../../assets/audios/soundsAr/ط.mp3';
//
import sound17 from './../../assets/audios/soundsAr/ظ.mp3';
import sound18 from './../../assets/audios/soundsAr/ع.mp3';
import sound19 from './../../assets/audios/soundsAr/غ.mp3';
import sound20 from './../../assets/audios/soundsAr/ف.mp3';
//
import sound21 from './../../assets/audios/soundsAr/ق.mp3';
import sound22 from './../../assets/audios/soundsAr/ك.mp3';
import sound23 from './../../assets/audios/soundsAr/ل.mp3';
import sound24 from './../../assets/audios/soundsAr/م.mp3';
//
import sound25 from './../../assets/audios/soundsAr/ن.mp3';
import sound26 from './../../assets/audios/soundsAr/ه.mp3';
import sound27 from './../../assets/audios/soundsAr/و.mp3';
import sound28 from './../../assets/audios/soundsAr/ي.mp3';

const ArKeyboard = () => {
  const start = (sound) => {
    new Audio(sound).play();
  };

  const soundToPlay = [
    {
      sound: sound1,
      title: 'ا',
    },
    {
      sound: sound2,
      title: 'ب',
    },
    {
      sound: sound3,
      title: 'ت',
    },
    {
      sound: sound4,
      title: 'ث',
    },
    {
      sound: sound5,
      title: 'ج',
    },
    {
      sound: sound6,
      title: 'ح',
    },
    {
      sound: sound7,
      title: 'خ',
    },
    {
      sound: sound8,
      title: 'د',
    },
    {
      sound: sound9,
      title: 'ذ',
    },
    {
      sound: sound10,
      title: 'ر',
    },
    {
      sound: sound11,
      title: 'ز',
    },
    {
      sound: sound12,
      title: 'س',
    },
    {
      sound: sound13,
      title: 'ش',
    },
    {
      sound: sound14,
      title: 'ص',
    },
    {
      sound: sound15,
      title: 'ض',
    },
    {
      sound: sound16,
      title: 'ط',
    },
    {
      sound: sound17,
      title: 'ظ',
    },
    {
      sound: sound18,
      title: 'ع',
    },
    {
      sound: sound19,
      title: 'غ',
    },
    {
      sound: sound20,
      title: 'ف',
    },
    {
      sound: sound21,
      title: 'ق',
    },
    {
      sound: sound22,
      title: 'ك',
    },
    {
      sound: sound23,
      title: 'ل',
    },
    {
      sound: sound24,
      title: 'م',
    },
    {
      sound: sound25,
      title: 'ن',
    },
    {
      sound: sound26,
      title: 'ه',
    },
    {
      sound: sound27,
      title: 'و',
    },
    {
      sound: sound28,
      title: 'ي',
    },
  ];

  return (
    <div className={styles.keyboardWrap}>
      <div className={styles.keyboardArabic}>
        {soundToPlay.map((sound, i) => (
          <button
            className={styles.soundButtonAR}
            key={`sound-to-learn-${i}`}
            onClick={() => start(sound.sound)}
          >
            {sound.title}
          </button>
        ))}
      </div>
    </div>
  );
};

export default ArKeyboard;
