import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { PaperSeperator } from '../../partials/home';
import { RecipeBanner, Popular, Listing } from '../../partials/recipes';

import { useGlobalContext } from '../../utils/state/GlobalContext';
import Layout from '../../components/Layout/Layout';

const RecipeListing = (props) => {
  const thisPageKey = 'recipes';
  //
  const { locale } = useParams();
  const { getPageContent, pageData, commonData, isLoaded } = useGlobalContext();
  //
  document.body.className = 'bg2';
  //
  const getRandomPopular = (arr, count) => {
    let randArr = [];
    let arrCopy = [...arr];
    for (let i = 0; i < count; i++) {
      const randomIndex = Math.floor(Math.random() * arrCopy.length);
      console.log(randomIndex);
      randArr.push(arrCopy[randomIndex]);
      arrCopy.splice(randomIndex, 1);
    }
    console.log(randArr);
    return randArr;
  };
  //
  useEffect(() => {
    const res = getPageContent(locale, thisPageKey);
    res.then((response) => {
      //alert ("HomePageDataLoaded")
    });
  }, []);

  return (
    <Layout bg={props.bg}>
      {isLoaded(thisPageKey) && isLoaded() ? (
        <>
          <RecipeBanner locale={locale} content={pageData.recipes.banner} />
          <PaperSeperator key="1" color="#fd9331" pos="bottom" />
          <Popular
            locale={locale}
            content={{
              title: pageData.recipes.popular.title,
              items: getRandomPopular(commonData.recipes.items, 5),
            }}
          />
          <Listing locale={locale} content={commonData.recipes} />
        </>
      ) : (
        <></>
      )}
    </Layout>
  );
};

export default RecipeListing;
