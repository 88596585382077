import { useState, useEffect } from "react";
import axios from "axios";
import { BE_URL } from "../../../utils/constants/Constants";
import swapImg from "../../../assets/images/Home/vector.png";
import upload from "../../../assets/images/Home/upload.png";
import videoImg from "../../../assets/images/Home/video.png";
import close from "../../../assets/images/Home/close.png";
import warning from "../../../assets/images/Home/warning.png";
import "./player.scss";
// import disabledBtn from "../../../assets/images/Home/disabledBtn.png";

import { ReactComponent as Repeat } from "../../../assets/images/MysteryPage/repeat.svg";
import PlayButton from "../../../partials/mysteryPage/PlayButtton/PlayButton";

import styles from "./translateForm.module.scss";
import VideoPopup from "../VideoPopup/VideoPopup";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import { useSearchParams } from "react-router-dom";

const videoTypes = ["video/mp4", "video/avi"];

function TranslateForm(props) {
  const [status, setStatus] = useState(true);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [file, setFile] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [open, setOpen] = useState(false);
  const [outputType, setOutputType] = useState(null);
  const [output, setOutput] = useState(null);
  const [soundEnded, setSoundEnded] = useState(false);
  const [soundName, setSoundName] = useState("");
  const [translatedSound, setTranslatedSound] = useState("");
  const [videoSrc, setVideoSrc] = useState("");
  const [downloadPath, setDownloadPath] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams();
  // remove extar space in text
  const removeExtraSpace = (s) => s.trim().split(/ +/).join(" ");
  const swap = () => {
    setStatus(!status);
    setImageSrc(null);
    setErrorMessage("");
    setMessage("");
    setFile(null);
    setOutputType(null);
    setOutput(null);
    setTranslatedSound("");
  };

  const decodeAgain = () => {
    setFile(null);
    setImageSrc(null);
    setErrorMessage("");
    setFile(null);
    setOutputType(null);
    setOutput(null);
    setTranslatedSound("");
  };

  const handleMessageChange = (event) => {
    // 👇️ access textarea value
    setMessage(event.target.value);
  };

  function handleChange(e) {
    const inputFile = e.target.files[0];
    setIsUploading(true);
    setFile(inputFile);

    validateVideo(inputFile);

    // const reader = new FileReader();
    // reader.onload = (e) => {
    //     setImageSrc(videoImg);
    //     console.log(e.target.result);
    //   setIsUploading(false);
    // };
    // reader.readAsDataURL(inputFile);
  }

  const validateVideo = (file) => {
    if (videoTypes.indexOf(file.type) === -1) {
      setErrorMessage(props.content.fileUploadErrExtension);
      setImageSrc(warning);
      return;
    }
    setErrorMessage("");
    setImageSrc(videoImg);
  };

  function handleRemoveUpload() {
    setFile(null);
    setImageSrc(null);
  }

  function handleDragOver() {
    setIsUploading(true);
  }

  function handleDragLeave() {
    setIsUploading(false);
  }

  function handleRemoveErr() {
    setImageSrc(null);
    setErrorMessage("");
  }

  // Handle Encode
  function handleEncode() {
    let input = removeExtraSpace(message);
    if (input == "" || input == null) {
      setErrorMessage(props.content.errTxtRequired);
      setImageSrc(warning);
      setIsLoading(false);
    } else {
      setIsLoading(true);
      return axios
        .get(`${BE_URL}/sanctum/csrf-cookie`)
        .then((response) => {
          axios
            .post(`${BE_URL}/api/video-translate`, { message: input })
            .then((response) => {
              if (response.data.status == "success") {
                setVideoSrc(response.data.data.output);
                setDownloadPath(response.data.data.download);
                setId(response.data.data.id);
                setOpen(true);
                setIsLoading(false);
              } else {
                setErrorMessage("Something went wrong!");
                setImageSrc(warning);
                setOutputType(null);
                setOutput(null);
                setIsLoading(false);
              }
            })
            .catch((error) => {
              console.log("error", error);
              setErrorMessage(error.message);
              setImageSrc(warning);
              setOutputType(null);
              setOutput(null);
              setIsLoading(false);
            });
        })
        .catch((error) => {
          setErrorMessage(error.message);
          setImageSrc(warning);
          setOutputType(null);
          setOutput(null);
          setIsLoading(false);
        });
    }

    // setVideoSrc('https://garameesh-api-dev.slz.io/translate/output/video/result/1672760822.mp4');
    // setOpen(true);
  }

  // Handle decode

  const handleDecode = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", file);
    setIsLoading(true);

    return axios
      .get(`${BE_URL}/sanctum/csrf-cookie`)
      .then((response) => {
        axios
          .post(`${BE_URL}/api/decode`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            if (response.data.status == "fail") {
              setErrorMessage(props.content.fileUploadErrfile);
              setImageSrc(warning);
              setOutputType(null);
              setOutput(null);
              setIsLoading(false);
            } else if (response.data.status == "success") {
              setOutputType(response.data.data.type);
              setOutput(response.data.data.message);
              setTranslatedSound(response.data.data.message);
              setSoundName(
                response.data.data.message
                  .split("/")
                  .slice(-1)
                  .join()
                  .split(".")
                  .shift()
              );
              setErrorMessage("");
              setImageSrc("");
              setIsLoading(false);
            }
          })
          .catch((error) => {
            console.log("error", error);
            setErrorMessage(error.message);
            setImageSrc(warning);
            setOutputType(null);
            setOutput(null);
            setIsLoading(false);
          });
      })
      .catch((error) => {
        setErrorMessage(error.message);
        setImageSrc(warning);
        setOutputType(null);
        setOutput(null);
        setIsLoading(false);
      });
  };

  function getVideo(id) {
    return axios.get(`${BE_URL}/sanctum/csrf-cookie`).then((response) => {
      axios
        .get(`${BE_URL}/api/get-video?id=${id}`)
        .then((response) => {
          if (response.data.status == "success") {
            setVideoSrc(response.data.data.output);
            setDownloadPath(response.data.data.download);
            setId(response.data.data.id);
            setOpen(true);
          }
          // setVideoSrc(response.data.data.output);
          // setDownloadPath(response.data.data.download);
          // setOpen(true);
        })
        .catch((error) => {
          console.log("error", error);
        });
    });
  }
  useEffect(() => {
    if (searchParams.get("id")) {
      const vId = searchParams.get("id");
      getVideo(vId);
    }
  }, []);

  return (
    <>
      {status ? (
        <>
          <div className={styles.garameesh}>
            <div className={styles.garameeshWrapper}>
              <ul>
                <li className={`${styles.w40} ${styles.garameeshLangBox}`}>
                  {props.content.garameeshBtn}
                </li>
                <li className={`${styles.w40} ${styles.garameeshSwap}`}>
                  {isLoading ? (
                    <>
                      <img src={swapImg} alt="swap" className={styles.swap} />
                    </>
                  ) : (
                    <>
                      <img
                        src={swapImg}
                        alt="swap"
                        className={styles.swap}
                        onClick={swap}
                      />
                    </>
                  )}
                </li>
                <li className={`${styles.w40} ${styles.garameeshLangBox}`}>
                  {props.content.enArBtn}
                </li>
              </ul>
            </div>
            <div className={styles.garameeshWrapper}>
              {outputType ? (
                outputType == "text" ? (
                  <>
                    <textarea
                      id="message"
                      className={`${styles.textarea}`}
                      name="message"
                      value={output}
                      disabled={true}
                    />
                  </>
                ) : (
                  <>
                    <AudioPlayer
                      layout="horizontal"
                      src={translatedSound}
                      showJumpControls={false}
                      defaultCurrentTime="00:00"
                      defaultDuration="00:00"
                      onPlay={() => setSoundEnded(false)}
                      onEnded={() => setSoundEnded(true)}
                      customIcons={{
                        play: { ...(soundEnded ? <Repeat /> : <PlayButton />) },
                      }}
                      header={soundName}
                    />
                  </>
                )
              ) : (
                <>
                  <div className={styles.fileupload}>
                    <div className={styles.fileupload}>
                      {!imageSrc && (
                        <>
                          <div className={styles.imageUploadWrap}>
                            <input
                              className={`${styles.fileUploadinput}`}
                              type="file"
                              onChange={handleChange}
                              accept={videoTypes.join(",")}
                            />
                            <div className={`${styles.dragText}`}>
                              <h3 title={props.content.uploadTxt}>
                                <img src={upload} /> {props.content.uploadTxt}
                              </h3>
                            </div>
                          </div>
                        </>
                      )}
                      {errorMessage ? (
                        <>
                          <div className={` ${styles.textErrorWrapper}`}>
                            <div className={styles.w20}>
                              <img src={imageSrc} />
                            </div>
                            <div className={styles.w80}>
                              <span className={styles.error_msg}>
                                {errorMessage}
                              </span>
                            </div>
                            <div className={styles.w20}>
                              <img
                                src={close}
                                onClick={handleRemoveErr}
                                className={` ${styles.imgClose}`}
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        imageSrc && (
                          <>
                            <div className={` ${styles.fileUploadContent}`}>
                              <div className={styles.w20}>
                                <img src={imageSrc} alt={file.name} />
                              </div>
                              <div className={styles.w60}>
                                <span>{file.name}</span>
                              </div>
                              <div className={styles.w20}>
                                {!isLoading ? (
                                  <>
                                    <img
                                      src={close}
                                      onClick={handleRemoveUpload}
                                      className={` ${styles.imgClose}`}
                                    />
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </>
                        )
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className={styles.garameeshWrapper}>
            {outputType ? (
              <>
                <a
                  className={styles.btn}
                  title={props.content.cta}
                  onClick={decodeAgain}
                >
                  {props.content.ctaTAgain}{" "}
                </a>
              </>
            ) : (
              <>
                {!errorMessage && imageSrc ? (
                  <>
                    {isLoading ? (
                      <>
                        <a
                          className={styles.btn}
                          title={props.content.cta}
                          disabled={isLoading}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgress color="warning" size="25px" />
                          </Box>
                        </a>
                      </>
                    ) : (
                      <>
                        <a
                          className={styles.btn}
                          title={props.content.cta}
                          onClick={handleDecode}
                          disabled={isLoading}
                        >
                          {props.content.cta}
                        </a>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {/* <img src={disabledBtn} /> */}
                    <a className={styles.disabledBtn} title={props.content.cta}>
                      {props.content.cta}{" "}
                    </a>
                  </>
                )}
              </>
            )}
          </div>
          <div className={styles.garameeshWrapper}>
            <p
              className={styles.link}
              dangerouslySetInnerHTML={{ __html: props.content.desc }}
            />
          </div>
        </>
      ) : (
        <>
          <div className={styles.garameesh}>
            <div className={styles.garameeshWrapper}>
              <ul>
                <li className={`${styles.w40} ${styles.garameeshLangBox}`}>
                  {props.content.enArBtn}
                </li>
                <li className={`${styles.w40} ${styles.garameeshSwap}`}>
                  {isLoading ? (
                    <>
                      <img src={swapImg} alt="swap" className={styles.swap} />
                    </>
                  ) : (
                    <>
                      <img
                        src={swapImg}
                        alt="swap"
                        className={styles.swap}
                        onClick={swap}
                      />
                    </>
                  )}
                </li>
                <li className={`${styles.w40} ${styles.garameeshLangBox}`}>
                  {props.content.garameeshBtn}
                </li>
              </ul>
            </div>
            <div className={styles.garameeshWrapper}>
              {!errorMessage ? (
                <>
                  <textarea
                    id="message"
                    className={`${styles.textarea}`}
                    name="message"
                    value={message}
                    onChange={handleMessageChange}
                    placeholder={props.content.placeholder}
                    disabled={isLoading}
                  />
                </>
              ) : (
                <>
                  <div className={` ${styles.textErrorWrapper}`}>
                    <div className={styles.w80}>
                      <img src={imageSrc} />
                      <span>{errorMessage}</span>
                    </div>
                    <div className={styles.w20}>
                      <img
                        src={close}
                        onClick={handleRemoveErr}
                        className={` ${styles.imgClose}`}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className={styles.garameeshWrapper}>
            {message ? (
              <>
                {isLoading ? (
                  <>
                    <a className={styles.btn} disabled={isLoading}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <CircularProgress color="warning" size="25px" />
                      </Box>
                    </a>
                  </>
                ) : (
                  <>
                    <a
                      onClick={handleEncode}
                      className={styles.btn}
                      disabled={isLoading}
                    >
                      {props.content.ctaAnother}
                    </a>
                  </>
                )}
              </>
            ) : (
              <>
                <a className={styles.disabledBtn} title={props.content.cta}>
                  {props.content.cta}{" "}
                </a>
              </>
            )}
          </div>
          <div className={styles.garameeshWrapper}>
            <p
              className={styles.link}
              dangerouslySetInnerHTML={{ __html: props.content.desc }}
            />
          </div>
        </>
      )}

      
      <VideoPopup
        videoId={id}
        downloadSrc={downloadPath}
        video={videoSrc}
        isOpen={open}
        setOpen={setOpen.bind(this)}
        props={props}
      />
    </>
  );
}

export default TranslateForm;
