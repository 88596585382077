import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  HomeBanner,
  Products,
  Randomizer,
  PaperSeperator,
} from '../../partials/home';

import { useGlobalContext } from '../../utils/state/GlobalContext';
import Layout from '../../components/Layout/Layout';
import DecodeSection from '../../partials/home/DecodeSection/DecodeSection';
import RandomizerPhase2 from '../../partials/home/Randomizer/RandomizerPhase2';
import TranslateSection from '../../partials/home/TranslateSection/TranslateSection';

const HomePage = () => {
  const thisPageKey = 'home';
  //
  const { locale } = useParams();
  const { getPageContent, commonData, pageData, isLoaded } = useGlobalContext();

  useEffect(() => {
    // document.body.className = locale === 'en' ? 'bg1' : 'bg11';
    document.body.className = 'bg1';
    const res = getPageContent(locale, thisPageKey);
    res.then((response) => {
      //alert ("HomePageDataLoaded")
    });
  }, []);

  return (
    <Layout>
      {isLoaded(thisPageKey) ? (
        <>
          <DecodeSection locale={locale} content={pageData.home.bannerNEW} />
          <PaperSeperator key="1" pos="bottom" cls="null" />
          {/* <HomeBanner locale={locale} content={pageData.home.banner} /> */}
          {/* <Randomizer
            locale={locale}
            content={{
              ...pageData.home.randomizer,
              items: commonData.recipes.items,
            }}
            key={Math.random()}
          /> */}

                  <RandomizerPhase2
                      locale={locale}
                      content={{
                          ...pageData.home.randomizer,
                          items: commonData.recipes.items,
                      }}
                      key={Math.random()}
                  />

          <PaperSeperator key="2" pos="top" cls="null" />

                  <TranslateSection locale={locale} content={pageData.home.translate} />

          <PaperSeperator key="3" pos="top" cls="setMargin" />
          <Products locale={locale} content={pageData.home.products} />
        </>
      ) : (
        <></>
      )}
    </Layout>
  );
};

export default HomePage;
