import styles from './PaperSeperator.module.scss';

const PaperSeperator = (props) => {
  return (
    <>
      {props.pos === 'bottom' ? (
        <span
          className={`${styles.paperSeperator} ${props.cls ==='setMargin' ? styles.setMargin: ''}`}
          style={{ backgroundColor: props.color }}
        ></span>
      ) : (
        <span
          className={`${styles.paperSeperatorTop} ${props.cls ==='setMargin' ? styles.setMargin: ''}`}
          style={{ backgroundColor: props.color }}
        ></span>
      )}
    </>
  );
};

export default PaperSeperator;
