import { useEffect } from 'react';
import { GlobalContextProvider } from './utils/state/GlobalContext';
import { Navigate, Route, Routes } from 'react-router-dom';

import {
  HomePage,
  NotFoundPage,
  RecipeListing,
  RecipeDetails,
  Static,
  LearnPage,
  RegistrationPage,
  QuizPage,
  MysteryRecipe,
  PrizesPage, 
  ProductsPage
} from './pages';

import Styles from './styles/App.module.scss';

const App = () => {
  useEffect(() => {
    /*setShowSpinner(true);
    const res = getHome();
    res.then((response) => {
      console.log ("HomePageDataLoaded")
    });*/
  }, []);

  return (
    <>
      <GlobalContextProvider>
        <Routes>
          <Route path="/" element={<Navigate to="/ar/home" />} exact />
          <Route path="/en" element={<Navigate to="/en/home" />} exact />
          <Route path="/ar" element={<Navigate to="/ar/home" />} exact />
          <Route path="/:locale/home" element={<HomePage />} />
          <Route path="/:locale/recipes" element={<RecipeListing bg="red" />} />
          <Route path="/:locale/recipes/:slug" element={<RecipeDetails />} />
          <Route path="/:locale/recipes/mystery-recipe" element={<MysteryRecipe />} />
          <Route path="/:locale/404" element={<NotFoundPage />} />
          <Route path="/:locale/t&c" element={<Static content="t&c" />} />
          <Route path="/:locale/pp" element={<Static content="pp" />} />
          <Route path="*" element={<Navigate to="/en/404" />} />
          {/* Phase 2 - Learning*/}
          <Route
            path="/learning"
            element={<Navigate to="/en/learning" />}
            exact
          />
          <Route path="/en" element={<Navigate to="/en/learning" />} exact />
          <Route path="/ar" element={<Navigate to="/ar/learning" />} exact />
          <Route path="/:locale/learning" element={<LearnPage />} />

          {/* Phase 2 - Registration*/}
          <Route path="/registration" element={<Navigate to="/en/registration" />} exact />
          <Route path="/en" element={<Navigate to="/en/registration" />} exact />
          <Route path="/ar" element={<Navigate to="/ar/registration" />} exact />
          <Route path="/:locale/registration" element={<RegistrationPage />} />

          {/* Phase 2 - Mystery Page*/}
          <Route path="/quiz" element={<Navigate to="/en/quiz" />} exact />
          <Route path="/en" element={<Navigate to="/en/quiz" />} exact />
          <Route path="/ar" element={<Navigate to="/ar/quiz" />} exact />
          <Route path="/:locale/quiz" element={<QuizPage />} />

          {/* Phase 2 - Prizes Page*/} 
          <Route path="/prizes" element={<Navigate to="/en/prizes" />} exact />
          <Route path="/en" element={<Navigate to="/en/prizes" />} exact />
          <Route path="/ar" element={<Navigate to="/ar/prizes" />} exact />
          <Route path="/:locale/prizes" element={<PrizesPage />} />

          {/* Phase 2 - Products Page*/} 
          <Route path="/products" element={<Navigate to="/en/products" />} exact />
          <Route path="/en" element={<Navigate to="/en/products" />} exact />
          <Route path="/ar" element={<Navigate to="/ar/products" />} exact />
          <Route path="/:locale/products" element={<ProductsPage />} />
        </Routes>
      </GlobalContextProvider>
    </>
  );
};

export default App;
