import { RedBtn } from '../../partials/common';
import { Link, useParams } from 'react-router-dom';
import image from './../../assets/images/MysteryPage/ruport.png';
import { ReactComponent as Arrow } from './../../assets/images/MysteryPage/arrowRight.svg';
import { Dialog } from '@mui/material';
import { useGlobalContext } from '../../utils/state/GlobalContext';

function CongratsPopupInProgress({ isOpen, setOpen }) {
  const { commonData } = useGlobalContext();

  const { locale } = useParams();

  const handleClickOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  return (
    <Dialog
      id="congratsPopupInProgress"
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <div className="popupWrapper">
        <div className="popupContent">
          <h2 id="alert-dialog-title-inProgress">{commonData.mysteryPage.popupTitleInProgress}</h2>
          <div className='imageSunBGMobile'> 
            <img src={image} alt="recipe look" className="sandwichImage" />
          </div>
          <div className='popupDesc'>{commonData.mysteryPage.popupDescInProgress}</div>
          <RedBtn
            autoFocus
            bigger
            href={`/${locale}/registration`}
            label={commonData.mysteryPage.popupCTAInProgress} />
          <Link className="recipeLink" onClick={handleClose}>
            {commonData.mysteryPage.popupCTAInProgressKeep}
            <Arrow />
          </Link>
        </div>
        <div className='imageSunBGInProgress'> 
          <img src={image} alt="recipe look" className="sandwichImage" />
        </div>
      </div>
    </Dialog>
  );
}

export default CongratsPopupInProgress;