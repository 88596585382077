import React from 'react';
import { Link } from "react-router-dom";
import styles from './Footer.module.scss';

const Footer = (props) => {

  return (
    <div className={styles.footer}> 
      <span dangerouslySetInnerHTML={ { __html:props.content.copy} }></span>
      <div className={ styles.btnWrap }>
        { 
          props.content.links.map ((link)=> {
            return <Link key={link.id} to={ `/${props.locale}${link.to}` }>{ link.label }</Link>
          })
        }
      </div>
    </div>
  )
}

export default Footer;