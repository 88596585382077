import styles from './DirectionsCarousel.module.scss';
import { useEffect, useState } from 'react';
import OwlCarousel_RTL from 'react-owl-carousel-rtl';
import OwlCarousel from 'react-owl-carousel';
import { useLocation } from 'react-router-dom';

const DirectionsCarousel = (props) => {
  const [ itemsPerSlide, setItemsPerSlide ] = useState (1);
  const location = useLocation();

  const yellow = location.pathname.includes('mystery');

  const DOM = (props.locale == "en") ? OwlCarousel : OwlCarousel_RTL;
  
  const resize = () =>{
    if (window.innerWidth <= 450) {
      setItemsPerSlide (1.2)
    }else {
      setItemsPerSlide (3)
    }

  }; 

  useEffect(() => {
    window.addEventListener("resize", resize);
    resize ();
  }); 

  return (
    <>
      <DOM rtl={true} dots={false} autoWidth='false' className='owl-theme' margin={30} nav={false}>
        { props.items.map (i => {
            return (
              <div key={`carousel-${i.title}`} className={ styles.card } >
                  <div className={yellow ? styles.wrapYellow : styles.wrap } >
                    <h3>{i.title}</h3>
                    <p>{i.desc}</p>
                  </div>
              </div>
            )
          })
        }
      </DOM>
    </>
  )
}

export default DirectionsCarousel;