import styles from './Listing.module.scss';
import { Link } from 'react-router-dom';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import MysteryRecipeCard from '../../../components/MysteryRecipeCard/MysteryRecipeCard';

const Listing = (props) => {
  console.log(props.content.items);
  return (
    <>
      <div className={styles.Listing}>
        <div className={styles.wrapper}>
          <h1>{props.content.title} </h1>
          <div className={styles.container}>
            <MysteryRecipeCard />
            {props.content.items.map((i) => {
              return (
                <AnimationOnScroll
                  animateIn="animate__fadeInUp"
                  animateOnce={true}
                  key={`animate-${i.title}`}>
                  <Link className={styles.item} to={`/${props.locale}/recipes/${i.slug}`}>
                    <img src={i.image} alt="recipe pic" />
                    <p>{i.title}</p>
                  </Link>
                </AnimationOnScroll>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Listing;
