import styles from './Products.module.scss';
import { RedBtn } from "../../common/index";
import ProductCarousel from "./ProductCarousel/ProductCarousel"

const Products = (props) => {
  
  return (
    <>
      <div className={styles.products} >
        <div className={styles.wrapper} >
          <h1>{props.content.title}</h1>
          <p>{props.content.desc}</p>

          <ProductCarousel items={props.content.items} locale={props.locale} key={Math.random()}/>

          <RedBtn style={styles.btn} label={props.content.cta} external={props.content.ctaAction} />
        </div>
      </div>
    </>
  )
}

export default Products;