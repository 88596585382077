import styles from './RecipesBanner.module.scss';

const RecipesBanner = (props) => {
  return (
    <>
      <div className={styles.recipeBanner}>
        <div className={styles.wrapper}>
          <h1>{props.content.title}</h1>
          <p>{props.content.desc}</p>
        </div>
      </div>
    </>
  );
};

export default RecipesBanner;
