import { useRef, useState } from "react";
import { RedBtn } from "../../common";
import { Link, useParams } from "react-router-dom";
import { APP_URL } from "../../../utils/constants/Constants";
import { Dialog } from "@mui/material";
import { useGlobalContext } from "../../../utils/state/GlobalContext";
import VideoJS from "../Randomizer/VideoJS";
import videojs from "video.js";
import playbtn from "../../../assets/images/Home/playbtn.png";
import download from "../../../assets/images/Home/download.png";
import share from "../../../assets/images/Home/share.png";
import close from "../../../assets/images/Home/close.png";

import InlineButtons from "./InlineButtons";

function VideoPopup({ videoId, downloadSrc, video, isOpen, setOpen, props }) {
  const playerRef = useRef(null);
  const { commonData } = useGlobalContext();

  const { locale } = useParams();

  const [playBtn, setplayBtn] = useState(true);
  const handleClickOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const videoJsOptions = {
    options: {
      poster: null,
      autoplay: false,
      controls: false,
      muted: false,
      preload: "auto",
      playsinline: true,
      fluid: true,
      sources: [
        {
          src: video,
          type: "video/mp4",
        },
      ],
    },
  };
  const playvideo = () => {
    playerRef.current.play();
    setplayBtn(false);
  };
  const handlePlayerReady = (player) => {
    playerRef.current = player;


    // You can handle player events here, for example:
    player.on("timeupdate", (e) => {
      const ct = player.currentTime();

      if ((ct <= 0) & !playBtn) {
        setplayBtn(true);
      } else if (ct > 0) {
        setplayBtn(false);
      }
    });
    player.on("waiting", () => {
      videojs.log("player is waiting");
    });

    player.on('ended', () => {
      setplayBtn(true);
    });

    player.on("dispose", () => {
      setplayBtn(true);
      videojs.log("player will dispose");
    });
  };

  return (
    <Dialog
      id="videoPopup"
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className="v_popupWrapper">
        <div className="v_popupContent">
          <h2 id="v_alert-dialog-title">{props.content.popupTitle}</h2>
          <a className="v_closeBtnWrap">
            <img src={close} className="v_closeBtn" onClick={handleClose} />
          </a>
          <div className="v_imageSunBG">
            {/* <img src={recipeImage} alt="recipe look" className="mysteryRecipeImage" /> */}
            <VideoJS {...videoJsOptions} onReady={handlePlayerReady} />
            {playBtn ? (
              <>
                <img src={playbtn} className="playBtn" onClick={playvideo} />
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="V_buttonWrapper">
            <a
              className="v_recipeLink"
              href={downloadSrc}
              target="_blank"
              download
            >
              <img src={download} className="downloadBtn" />{" "}
              {props.content.download}
            </a>

            {/* <a className="v_shareBtn"> */}
              {/* <img src={share} className="downloadBtn" /> */}
              <InlineButtons url={`${APP_URL+locale}/home?id=${videoId}`} />
            {/* </a> */}
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default VideoPopup;
