import { Dialog } from '@mui/material';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useGlobalContext } from '../../utils/state/GlobalContext';

export default function RegSuccessPopup({ isOpen }) {
  const [open, setOpen] = useState(isOpen);

  const { commonData } = useGlobalContext();
  const { locale } = useParams();


  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setTimeout(() => {

      if (localStorage.ingredient_1 && 
        localStorage.ingredient_2 && 
        localStorage.ingredient_3 && 
        localStorage.ingredient_4) {
        navigate(`/${locale}/recipes/mystery-recipe`);
      } else {
        navigate(`/${locale}/quiz`);
      }
      
    }, 1600);
  }, []);

  return (
    <Dialog
      id="regSuccessPopup"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className="popupWrapper">
        <div className="popupContent">
          <h2 id="alert-dialog-title">
            {commonData.registrationPage.popupTitle}
          </h2>
          <p id="alert-dialog-description">
            {commonData.registrationPage.popupDesc}
          </p>
        </div>
      </div>
    </Dialog>
  );
}
