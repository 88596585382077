import Layout from '../../components/Layout/Layout';
import styles from './NotFoundPage.module.scss';
import notFoundImg from '../../assets/images/404.png';
import { RedBtn } from '../../partials/common';
import { useParams } from 'react-router-dom';
import { useGlobalContext } from '../../utils/state/GlobalContext';

const NotFoundPage = () => {
  const thisPageKey = 'notFoundPAge';

  const { locale } = useParams();
  const { commonData, isLoaded } = useGlobalContext();

  document.body.className = 'bg1';

  return (
    <Layout>
      {isLoaded() ? (
        <div className={styles.notFound}>
          <img src={notFoundImg} />
          <h1>{commonData.notFound.title}</h1>
          <p>{commonData.notFound.desc} </p>
          <RedBtn
            href={`/${locale}${commonData.notFound.ctaAction}`}
            label={commonData.notFound.cta}
          ></RedBtn>
        </div>
      ) : (
        <></>
      )}
    </Layout>
  );
};

export default NotFoundPage;
