import styles from './Popular.module.scss';
import PopularCarousel from './PopularCarousel/PopularCarousel';

const Popular = (props) => {
  return (
    <>
      <div
        className={
          !props.hideBG ? `${styles.Popular} ${styles.hideBG}` : styles.Popular
        }
      >
        <div className={styles.wrapper}>
          <h1>{props.content.title} </h1>
          {/*<p>{ props.content.desc}</p> */}
          <PopularCarousel locale={props.locale} items={props.content.items} />
        </div>
      </div>
    </>
  );
};

export default Popular;
