import styles from './PopularCarousel.module.scss';
import { Link } from 'react-router-dom';
import OwlCarousel_RTL from 'react-owl-carousel-rtl';
import OwlCarousel from 'react-owl-carousel';

const PopularCarousel = (props) => {
  const DOM = (props.locale == "en") ? OwlCarousel : OwlCarousel_RTL;

  return (
    <DOM rtl={true} dots={false} autoWidth='false' className='owl-theme' margin={30} nav={false}>
      { props.items.map(i => {
          return (
            <Link key={`popularLink-${i.title}`} className={ styles.card } to={`/${props.locale}/recipes/${i.slug}`  } >
              <div className={ styles.wrap } >
                <img src={i.image} />
                <span>{i.title}</span>
              </div>
            </Link>
          )
        })
      }
    </DOM>
  )
}

export default PopularCarousel;