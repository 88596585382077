import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import pageSeparator from './../../../assets/images/BG/sidebar.png';
import { ReactComponent as Burger } from './../../../assets/images/svg/burgerMenu.svg'

import { Link, NavLink, useParams } from 'react-router-dom';
import { useGlobalContext } from '../../../utils/state/GlobalContext';

export default function Sidebar() {
  const { locale } = useParams();
  const { commonData } = useGlobalContext();

  const allUnlocked = 
    localStorage.ingredient_1 &&
    localStorage.ingredient_2 &&
    localStorage.ingredient_3 &&
    localStorage.ingredient_4;

  const [open, setOpen] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpen({ ...open, [anchor]: open });
  };

  const list = (anchor) => (
    <Box role="presentation" onClick={toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
      <List>
        <ListItem>
          <ListItemButton>
            <NavLink to={`/${locale}/home`} className="sidebar-nav-item">
              {commonData.sidebar.home}
            </NavLink>
          </ListItemButton>
        </ListItem>

        <ListItem>
          <ListItemButton>
            <NavLink to={`/${locale}/products`} className="sidebar-nav-item">
              {commonData.sidebar.products}
            </NavLink>
          </ListItemButton>
        </ListItem>

        <ListItem>
          <ListItemButton>
            <NavLink to={`/${locale}/recipes`} className="sidebar-nav-item">
              {commonData.sidebar.recipes}
            </NavLink>
          </ListItemButton>
        </ListItem>

        <ListItem>
          <ListItemButton>
            <NavLink to={`/${locale}/learning`} className="sidebar-nav-item">
              {commonData.sidebar.learn}
            </NavLink>
          </ListItemButton>
        </ListItem>

        <ListItem>
          <ListItemButton>
            <NavLink to={`/${locale}/prizes`} className="sidebar-nav-item">
              {commonData.sidebar.prizes}
            </NavLink>
          </ListItemButton>
        </ListItem>

        <ListItem>
          <ListItemButton>
            <NavLink to={`/${locale}/${allUnlocked ? 'recipes/mystery-recipe' : 'quiz'}`} className="sidebar-nav-item">
              {commonData.sidebar.mystery}
            </NavLink>
          </ListItemButton>
        </ListItem>
      </List>

      <div className="sidebar-footer">
        <Link
          className="sidebar-footer-link"
          to={`/${locale}${commonData.footer.links[0].to}`}>
          {commonData.footer.links[0].label}
        </Link>
      </div>
    </Box>
  );

  return (
    <div>
      {[locale === 'en' ? 'left' : 'right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <button className="burgerButton" onClick={toggleDrawer(anchor, true)}>
            <Burger/>
          </button>
          <Drawer
            anchor={anchor}
            open={open[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
            <img
              src={pageSeparator}
              className="pageSeparatorSidebarLeft"
              alt="paper divider"
            />
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
