import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Popular, Directions, Ingredients } from '../../partials/recipes';
import { PaperSeperator } from '../../partials/home';
import { useGlobalContext } from '../../utils/state/GlobalContext';
import Layout from '../../components/Layout/Layout';
import styles from './RecipeDetails.module.scss';
import { InlineShareButtons } from 'sharethis-reactjs';

const RecipeDetails = () => {
  const { locale, slug } = useParams();
  const thisPageKey = 'recipeDetails';
  //
  const { getPageContent, pageData } = useGlobalContext();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    document.body.className = 'bg1';
    const res = getPageContent(locale, thisPageKey, slug);

    res.then((response) => {
      setIsReady(true);
      // console.log  (">>>>",  pageData);
      // console.log  (">>>>",  pageData.recipeDetails, slug);
    });
  }, [slug]);

  return (
    <Layout>
      {pageData.recipeDetails[slug] ? (
        <>
          <div className={styles.detailsPage}>
            <div className={styles.banner}>
              <img className={styles.bannerImg} src={`${pageData.recipeDetails[slug].image}`} />
              <div className={styles.txtwrap}>
                <h1>{pageData.recipeDetails[slug].title}</h1>
                <p>{pageData.recipeDetails[slug].desc}</p>
                <div className={styles.actionBar}>
                <p className={styles.shareCTA}>{locale === 'en' ? 'Share It Now' : 'شاركوها الآن'}</p>
                  <InlineShareButtons
                    config={{
                      alignment: locale == 'en' ? 'left' : 'right',
                      color: 'social',
                      enabled: true,
                      font_size: 16,
                      labels: 'null',
                      language: 'en',
                      networks: [
                        'whatsapp',
                        'messenger',
                        'facebook',
                        'twitter',
                      ],
                      padding: 12,
                      radius: 4,
                      show_total: false,
                      size: 40,

                      url: `https://www.garameesharabia.com/${locale}/${slug}`,
                      description: `${pageData.recipeDetails[slug].title}`, // (defaults to og:description or twitter:description)
                      title: `${pageData.recipeDetails[slug].desc}`, // (defaults to og:title or twitter:title)
                      //message: 'custom email text',     // (only for email sharing)
                      //subject: 'custom email subject',  // (only for email sharing)
                      //username: 'custom twitter handle' // (only for twitter sharing)
                    }}
                  />
                  {
                    //<Link className= { styles.downloadBtn}>Download recipe as PDF</Link>
                    //<Link className= { styles.shareBtn}>Share recipe</Link>
                  }
                </div>
              </div>
            </div>

            <div className={styles.ingWrap}>
              {pageData.recipeDetails[slug].ing.map((ingGroup) => {
                return (
                  <Ingredients
                    key={ingGroup.title + Math.random()}
                    content={ingGroup}
                  />
                );
              })}
            </div>

            <div className={styles.instWrap}>
              {pageData.recipeDetails[slug].inst.map((instrGroup) => {
                if (instrGroup.items.length > 0) {
                  return (
                    <Directions
                      key={instrGroup.title}
                      map={instrGroup}
                      locale={locale}
                      content={instrGroup}
                    />
                  );
                }
              })}
            </div>
          </div>
          {false && <PaperSeperator color="#fd9331" pos="bottom" />}
          <Popular
            locale={locale}
            yellow={true}
            content={pageData.recipeDetails[slug].related}
          />
        </>
      ) : (
        <></>
      )}
    </Layout>
  );
};

export default RecipeDetails;
