import { Link } from "react-router-dom";
import styles from "./RedBtn.module.scss";

const RedBtn = (props) => {

  return (
    <div className= {props.style ? props.style : ""}>
    {props.bigger ? <Link to={props.href} className={styles.biggerBtn}>{props.label}</Link> : 
    <>
      {props.onClick && <a onClick={()=>{props.onClick()}} className={props.color === 'yellow' ? styles.yellowBtn : styles.redBtn}>{ props.label } </a>}
      {props.href && <Link to={props.href} className={props.color === 'yellow' ? styles.yellowBtn : styles.redBtn}>{props.label}</Link>}
      {props.external && <a href={ props.external } target="_blank" className={styles.redBtn}>{props.label}</a>}
    </>
    }
    </div>
  )
}

export default RedBtn;