import { RedBtn } from '../../partials/common';
import { Link, useParams } from 'react-router-dom';
import recipeImage from './../../assets/images/MysteryPage/recipeImage.png';
import { ReactComponent as Arrow } from './../../assets/images/MysteryPage/arrowRight.svg';
import { Dialog } from '@mui/material';
import { useGlobalContext } from '../../utils/state/GlobalContext';

function CongratsPopupCompleted({ isOpen, setOpen }) {
  const { commonData } = useGlobalContext();

  const { locale } = useParams();

  const handleClickOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  return (
    <Dialog
      id="congratsPopup"
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <div className="popupWrapper">
        <div className="popupContent">
          <h2 id="alert-dialog-title">{commonData.mysteryPage.popupTitle}</h2>
          <div className='imageSunBGMobile'> 
            <img src={recipeImage} alt="recipe look" className="mysteryRecipeImage" />
          </div>
          <div className='popupDesc'>{commonData.mysteryPage.popupDesc}</div>
          <RedBtn
            autoFocus
            bigger
            href={`/${locale}/recipes/mystery-recipe`}
            label={commonData.mysteryPage.viewFull} />
          {localStorage.firstName ? 
            <Link className="recipeLink" to={`/${locale}/prizes`}>{commonData.mysteryPage.viewPrizes} <Arrow /></Link> :
            <Link className="recipeLink" to={`/${locale}/registration`}>{commonData.mysteryPage.popupCTA} <Arrow /></Link> 
          }  
          
        </div>
        <div className='imageSunBG'> 
          <img src={recipeImage} alt="recipe look" className="mysteryRecipeImage" />
        </div>
      </div>
    </Dialog>
  );
}

export default CongratsPopupCompleted;